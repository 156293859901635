.staff {

  .wrap {
    background-image: url("../img/staff.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    max-width: 100%;
    z-index: 1;
    padding-bottom: 100px;

    .staff__title {
      display: flex;
      justify-content: center;

      h1 {
        padding-top: 50px;
        font-family: 'Abhaya Libre', serif;
        font-size: 4.38em;
        line-height: 1em;
        font-weight: 800;
        color: $line-menu;
      }
    }
    .devider {
      @include devider()
    }

    .staff__block {

      .staff__wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .staff__info {
          display: flex;
          margin: 1.250em;
          background: $white;
          box-shadow: 0 0 5px;
          border-radius: 0.625em;
          padding: 10px;

          a {
            text-decoration: none;
          }

          .title {
            font-size: 1.250em;
            line-height: 1.250em;
            color: $line-menu;
            font-weight: 700;
            text-align: center;
            margin-bottom: 0.313em;
          }

          p {
            font-size: 1.125em;
            line-height: 25px;
            max-width: 34.063em;
            font-weight: 400;
            color: $dark-gray;
            text-align: center;
            padding: 2px;
          }

          &:hover {
            box-shadow: 0.750em 0.750em 0.750em 0.063em rgba(0, 0, 0, 0.5);
          }
        }

      }
    }
  }
}

@media screen and (max-width: 1024px){

  .staff {

    .wrap {
      padding-bottom: 50px;
    }
  }

}

@media screen and (max-width: 960px){

  .staff {

    .wrap {

      .staff__title {
        h1 {
          padding-top: 30px;
        }
      }

    }
  }

}

@media screen and (max-width: 860px){

  .staff {

    .wrap {

      .staff__title {
        h1 {
          font-size: 3.5em;
        }
      }

    }
  }
}

@media screen and (max-width: 700px) {

  .staff {

    .wrap {
      padding-bottom: 20px;

      .staff__title {
        h1 {
          font-size: 2.5em;
        }
      }

      .staff__block {

        .staff__wrap {

          .staff__info {
            margin: 10px;

            p {
              font-size: 1.2em;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 525px){
  .staff {

    .wrap {

      .staff__title {
        h1 {
          font-size: 1.7em;
          padding-top: 15px;
        }
      }

      .devider {

        p {

          &:after {
            width: 5px;
            height: 5px;
          }
        }

        &::after, &::before {
          width: 60px;
        }

        &::before {
          left: -70px;

        }

        &::after {
          right: -70px;
        }
      }

      .staff__block {

        .staff__wrap {

          .staff__info {
            margin: 5px;

            .title {
              font-size: 1em;
            }

            p {
              padding: 0;
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px){
  .staff {

    .wrap {

      .staff__title {
        h1 {
          font-size: 1.4em;
          padding-top: 10px;
        }
      }

      .staff__block {

        .staff__wrap {

          .staff__info {

            .title {
              font-size: 1em;
            }

            p {
              font-size: 0.875em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 365px){
  .staff {

    .wrap {
      padding-bottom: 5px;

      .staff__block {

        .staff__wrap {

          .staff__info {

            .title {
              font-size: 0.750em;
            }

            p {
              font-size: 0.750em;
            }
          }
        }
      }
    }
  }
}



