.information {
  padding-top: 120px;
  background-color: $white-gray;

  .information__wrap {
    display: flex;
    align-items: center;
    margin-top: 80px;

    .information__left {
      flex-basis: 665px;
      position: relative;
      z-index: 1;
      background-color: $white;
      box-shadow: 0.438em 0.438em 0.313em rgba(0,0,0,0.6);
      padding: 30px 50px 80px;
      box-sizing: border-box;
      border-radius: 0.625em;


      h2 {
        font-size: 1.250em;
        line-height: 1.250em;
        color: $dark-gray;
        font-weight: 400;
        margin-bottom: 1.875em;
      }
       h1 {
         font-family: 'Abhaya Libre', serif;
         font-size: 3.750em;
         line-height: 1em;
         font-weight: 800;
         color: $slider;
         position: relative;
         padding-bottom: 0.88em;

         &:after {
           content:"";
           position:absolute;
           top: 0.88em;
           left: 0.10em;
           width: 1.2em;
           height: 0.13em;
           margin-top: 0.25em;
           border-bottom: solid $line-menu;
         }
       }

      p {
        font-size: 1.250em;
        line-height: 1.938em;
        max-width: 34.063em;
        font-weight: 400;
        color: $dark-gray;
        text-align: justify;
      }
    }

    .information__photo {

      img {
        display: block;
        margin-left: -100px;
        max-width: 600px;
        max-height: 700px;
        box-shadow: 0.438em 0.438em 0.313em rgba(0,0,0,0.6);
        border-radius: 0.625em;
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  .information {
    padding: 50px 0;

    .information__wrap {

      .information__left {
        flex-basis: 50%;
        padding: 30px;
      }

      .information__photo {
        flex-basis: 50%;

        img {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}


@media screen and (max-width: 960px) {

  .information {

    .information__wrap {
      flex-direction: column;

      .information__left {
        padding: 20px;
      }

      .information__photo {
        margin-top: 30px;
      }

    }
  }
}

@media screen and (max-width: 860px) {

  .information {
    padding: 25px 0;

    .information__wrap {

      .information__left {
        flex-basis: 100%;

        h2 {
          font-size: 1.2em;
          line-height: 1.5em;
        }

        h1 {
          font-size: 3.5em;
        }
      }

      .information__photo {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 700px) {

  .information {
    padding: 20px 0;

    .information__wrap {
      margin-top: 0;

      .information__left {

        h2 {
          font-size: 1em;
          line-height: 1em;
        }

        h1 {
          font-size: 2.5em;
        }

        p {
          font-size: 1.2em;
          line-height: 1.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {

  .information {
    padding: 10px 0;
    margin: 0;

    .information__wrap {
      margin-top: 10px;

      .information__left {

        h2 {
          font-size: 0.875em;
        }

        h1 {
          font-size: 1.5em;
        }

        p {
          font-size: 1em;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {

  .information {

    .information__wrap {

      .information__left {

        h1 {
          font-size: 1.4em;
        }

        p {
          font-size: 0.875em;
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {

  .information {

    .information__wrap {

      .information__left {

        h2 {
          font-size: 0.750em;
        }

        p {
          font-size: 0.750em;
        }
      }
    }
  }
}