@charset "UTF-8";
/**
 * Сброс стилей
 **/
@import url("https://fonts.googleapis.com/css?family=Abhaya+Libre:400,800|Quicksand:400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

input {
  outline: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

/**
 * Основные стили
 **/
body {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px; }

@font-face {
  font-display: auto; }

.container {
  min-width: 320px;
  max-width: 100%;
  padding: 0 0;
  box-sizing: border-box;
  margin: 0 auto; }

.wrap {
  min-width: 320px;
  max-width: 1200px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto; }

p {
  color: #444444; }

h2 {
  color: #444444; }

.container #toTop {
  width: 60px;
  text-align: center;
  padding: 5px;
  position: fixed;
  bottom: 20px;
  right: 5px;
  cursor: pointer;
  height: 20px;
  vertical-align: middle; }

.top__container {
  border-bottom: 2px solid #f2f2f2;
  padding: 0.63em 0; }
  .top__container .top__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .top__container .top__wrap .top__left {
      max-width: 100%; }
      .top__container .top__wrap .top__left .top__logo img {
        display: block;
        max-width: 300px; }
      .top__container .top__wrap .top__left .top__logo a:focus {
        outline: none; }
    .top__container .top__wrap .top__right {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 15px 0; }
      .top__container .top__wrap .top__right .top__clock {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 1.250em; }
        .top__container .top__wrap .top__right .top__clock .img__time {
          padding: 0.63em; }
          .top__container .top__wrap .top__right .top__clock .img__time img {
            display: block;
            max-width: 50px; }
        .top__container .top__wrap .top__right .top__clock .top__contact {
          margin: 6% 0; }
          .top__container .top__wrap .top__right .top__clock .top__contact p:first-of-type {
            font-size: 0.88em;
            line-height: 1.5em;
            color: #000000;
            font-weight: 400; }
          .top__container .top__wrap .top__right .top__clock .top__contact p:last-of-type {
            font-size: 0.88em;
            line-height: 1em;
            color: #000000;
            font-weight: 400;
            padding-top: 5px; }
      .top__container .top__wrap .top__right .top__phone {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 1.250em; }
        .top__container .top__wrap .top__right .top__phone .img__phone {
          padding: 0.63em; }
          .top__container .top__wrap .top__right .top__phone .img__phone img {
            max-width: 50px;
            display: block; }
        .top__container .top__wrap .top__right .top__phone .top__contact {
          margin: 6% 0; }
          .top__container .top__wrap .top__right .top__phone .top__contact p {
            font-size: 0.88em;
            line-height: 1.5em;
            color: #000000;
            font-weight: 400;
            padding-bottom: 5px; }
          .top__container .top__wrap .top__right .top__phone .top__contact a {
            font-size: 0.88em;
            line-height: 1em;
            color: #000000;
            font-weight: 400;
            color: #000000;
            text-decoration: none;
            font-weight: 400; }
      .top__container .top__wrap .top__right .top__route {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 0; }
        .top__container .top__wrap .top__right .top__route .img__route {
          padding: 0.63em; }
          .top__container .top__wrap .top__right .top__route .img__route img {
            display: block;
            max-width: 52px; }
        .top__container .top__wrap .top__right .top__route .top__contact {
          margin: 8% 0; }
          .top__container .top__wrap .top__right .top__route .top__contact p:first-of-type {
            font-size: 0.88em;
            line-height: 1.5em;
            color: #000000;
            font-weight: 400; }
          .top__container .top__wrap .top__right .top__route .top__contact p:last-of-type {
            font-size: 0.88em;
            line-height: 1em;
            font-weight: 400;
            color: #000000;
            padding-top: 5px; }

@media screen and (max-width: 1024px) {
  .top__container {
    padding: 0.31em 0 0.31em 0; }
    .top__container .top__wrap .top__right {
      display: none; }
    .top__container .top__wrap .top__left {
      margin: 0 auto; } }

@media screen and (max-width: 525px) {
  .container #toTop {
    right: -22px; }
    .container #toTop img {
      max-width: 18px; }
  .top__container .top__wrap .top__left .top__logo img {
    max-width: 250px; } }

@media screen and (max-width: 365px) {
  .top__container .top__wrap .top__left .top__logo img {
    max-width: 200px; } }

.top__menu {
  border-bottom: 0.13em solid #f2f2f2; }
  .top__menu .menu #menu__toggle {
    opacity: 0; }
  .top__menu .menu ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .top__menu .menu li {
    text-align: center;
    padding-right: 2.2em; }
  .top__menu .menu a {
    font-size: 0.88em;
    line-height: 3.4em;
    color: #444444;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 1em;
    border-bottom: 0.19em solid transparent;
    -webkit-duration: 300ms;
    -moz-transition-duration: 300ms;
    -ms-duration: 300ms;
    -webkit-transition-property: color, border-bottom-color;
    -ms-transition-property: color, border-bottom-color;
    -moz-transition-property: color, border-bottom-color;
    transition-duration: 300ms;
    transition-property: color, border-bottom-color; }
    .top__menu .menu a:hover {
      width: 1.7em;
      color: #6b4c76;
      border-bottom-color: #6b4c76; }

@media screen and (max-width: 1024px) {
  .top__menu .menu ul {
    justify-content: center; } }

@media screen and (max-width: 768px) {
  .top__menu {
    border-bottom: none; }
    .top__menu .menu {
      position: fixed;
      z-index: 6; }
      .top__menu .menu li {
        padding-right: 0.63em; }
  .menu__btn {
    display: flex;
    /* используем flex для центрирования содержимого */
    align-items: center;
    /* центрируем содержимое кнопки */
    position: fixed;
    top: 2em;
    left: 1.3em;
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    z-index: 6; }
  /* добавляем "гамбургер" */
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    width: 100%;
    height: 0.13em;
    background-color: #6b4c76;
    z-index: 5;
    position: absolute; }
  .menu__btn > span::before {
    content: '';
    top: -0.8em; }
  .menu__btn > span::after {
    content: '';
    top: 0.8em; }
  .menu__box {
    display: flex;
    flex-direction: column;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;
    width: 19em;
    height: 100%;
    margin: 0;
    padding: 2.8em 0;
    list-style: none;
    text-align: center;
    background-color: #EFEFEF;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
    z-index: 5; }
  /* элементы меню */
  .menu__item {
    display: block;
    padding: 0.63em;
    color: #333;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
    font-weight: 400;
    text-decoration: none; }
  .menu__item:hover {
    background-color: #CFD8DC; }
  #menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
    z-index: 1000; }
  #menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0); }
  #menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg); }
  #menu__toggle:checked ~ .menu__box {
    visibility: visible;
    left: 0; }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    transition-duration: .25s; }
  .menu__box {
    transition-duration: .15s; }
  .menu__item {
    transition-duration: .15s; } }

@media screen and (max-width: 700px) {
  .menu__box {
    width: 16em;
    padding: 2em 0; }
  .menu__item {
    font-size: 1.1em; } }

@media screen and (max-width: 600px) {
  .menu__box {
    width: 12em;
    padding: 0; } }

@media screen and (max-width: 430px) {
  .menu__box {
    width: 10em;
    padding: 0; }
  .menu__item {
    font-size: 1em; } }

.slider__item .slider__img {
  position: relative;
  background-size: cover;
  margin: 0 auto;
  z-index: 1; }
  .slider__item .slider__img img {
    display: block;
    width: 100%; }

.slider__item .slider__info {
  position: absolute;
  left: 8.1em;
  top: 10em;
  z-index: 2; }
  .slider__item .slider__info .info {
    max-width: 45em;
    font-size: 1.3em;
    line-height: 1.9em;
    font-weight: 400;
    color: #444444; }
  .slider__item .slider__info h2 {
    font-size: 1.6em;
    line-height: 2.3em;
    contain: #444444;
    font-weight: 400;
    padding-bottom: 1.9em; }
  .slider__item .slider__info .slider__title {
    max-width: 100%;
    position: relative;
    font-family: 'Abhaya Libre', serif;
    font-size: 4.38em;
    line-height: 1em;
    font-weight: 800;
    color: #6b4c76;
    padding-bottom: 0.88em; }
    .slider__item .slider__info .slider__title:after {
      content: "";
      position: absolute;
      top: 0.88em;
      left: 0.10em;
      width: 1.2em;
      height: 0.13em;
      margin-top: 0.25em;
      border-bottom: solid #cf83ce; }

.owl-nav button.owl-prev {
  background: url("../../img/back.png") 0 0 no-repeat;
  width: 3.063em;
  height: 3.063em;
  border: none;
  box-shadow: none;
  position: absolute;
  left: 0.313em;
  top: 50%;
  cursor: pointer;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  .owl-nav button.owl-prev:focus {
    outline: none; }

.owl-nav button.owl-next {
  background: url("../../img/next.png") 0 0 no-repeat;
  width: 3.063em;
  height: 3.063em;
  border: none;
  box-shadow: none;
  position: absolute;
  right: 0.313em;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%); }
  .owl-nav button.owl-next:focus {
    outline: none; }

.owl-nav .owl-dots {
  display: none; }

@media screen and (max-width: 1540px) {
  .slider__item .slider__info {
    left: 5.5em;
    top: 70px; } }

@media screen and (max-width: 1140px) {
  .slider__item .slider__info {
    left: 5.5em;
    top: 40px; }
    .slider__item .slider__info h2 {
      padding-bottom: 20px; } }

@media screen and (max-width: 1024px) {
  .slider__item .slider__info {
    left: 5.5em;
    top: 20px; }
    .slider__item .slider__info .slider__title {
      padding-bottom: 0.625em; }
    .slider__item .slider__info .buttons {
      margin-top: 45px; } }

@media screen and (max-width: 960px) {
  .slider .slider__item .slider__info {
    left: 1em;
    top: 15px; }
    .slider .slider__item .slider__info .buttons {
      margin-top: 32px; }
    .slider .slider__item .slider__info h2 {
      padding-bottom: 10px; }
    .slider .slider__item .slider__info .info {
      padding-top: 30px; }
    .slider .slider__item .slider__info .slider__title {
      padding-bottom: 2px; }
  .slider .owl-dots {
    text-align: center;
    position: absolute;
    bottom: 5%;
    left: 50%; }
    .slider .owl-dots .owl-dot {
      width: 0.625em;
      height: 0.625em;
      border-radius: 0.625em;
      background-color: #fff;
      box-sizing: border-box;
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0 5px;
      cursor: pointer; }
      .slider .owl-dots .owl-dot:focus {
        outline: none; }
    .slider .owl-dots .active {
      background-color: #6b4c76; }
  .slider .owl-nav {
    display: none; } }

@media screen and (max-width: 860px) {
  .slider .slider__item .slider__info .info {
    color: #222222;
    padding-top: 15px; }
  .slider .slider__item .slider__info .h2 {
    font-size: 1.2em;
    color: #000000;
    line-height: 1.5em;
    padding-bottom: 1.5em; }
  .slider .slider__item .slider__info .slider__title {
    font-size: 3.5em;
    color: #6b4c76; } }

@media screen and (max-width: 768px) {
  .slider .slider__item .slider__info {
    top: 10px; }
    .slider .slider__item .slider__info h2 {
      font-size: 1.2em; }
    .slider .slider__item .slider__info .slider__title {
      padding-bottom: 15px; }
    .slider .slider__item .slider__info .info {
      font-size: 1.250em;
      padding-top: 5px; }
    .slider .slider__item .slider__info .buttons {
      margin-top: 10px; } }

@media screen and (max-width: 700px) {
  .slider .slider__item {
    padding: 0; }
    .slider .slider__item .slider__info {
      top: 15px; }
      .slider .slider__item .slider__info .slider__title {
        font-size: 2.5em; }
      .slider .slider__item .slider__info h2 {
        font-size: 1em;
        line-height: 5px; }
      .slider .slider__item .slider__info .info {
        max-width: 100%;
        text-align: justify;
        font-size: 1.2em;
        line-height: 1.5em;
        padding-right: 5px; } }

@media screen and (max-width: 525px) {
  .slider .slider__item .slider__info h2 {
    font-size: 0.875em; }
  .slider .slider__item .slider__info .slider__title {
    font-size: 1.5em; }
  .slider .slider__item .slider__info .info {
    font-size: 1em;
    font-weight: 400; } }

@media screen and (max-width: 430px) {
  .slider .slider__item .slider__info .slider__title {
    font-size: 1.4em; }
  .slider .slider__item .slider__info .info {
    font-size: 0.875em; } }

@media screen and (max-width: 414px) {
  .slider .slider__info {
    top: 15px; } }

@media screen and (max-width: 365px) {
  .slider .owl-dots .owl-dot {
    width: 0.313em;
    height: 0.313em;
    border-radius: 0.313em; }
  .slider .slider__item .slider__info h2 {
    font-size: 0.750em; }
  .slider .slider__item .slider__info .info {
    font-size: 0.750em;
    padding: 0; }
  .slider .slider__item .slider__info .slider__title {
    font-size: 1.2em; } }

@media screen and (max-width: 320px) {
  .slider .slider__item .slider__info {
    top: 0.88em; } }

.information {
  padding-top: 120px;
  background-color: #f4f5f9; }
  .information .information__wrap {
    display: flex;
    align-items: center;
    margin-top: 80px; }
    .information .information__wrap .information__left {
      flex-basis: 665px;
      position: relative;
      z-index: 1;
      background-color: #fff;
      box-shadow: 0.438em 0.438em 0.313em rgba(0, 0, 0, 0.6);
      padding: 30px 50px 80px;
      box-sizing: border-box;
      border-radius: 0.625em; }
      .information .information__wrap .information__left h2 {
        font-size: 1.250em;
        line-height: 1.250em;
        color: #444444;
        font-weight: 400;
        margin-bottom: 1.875em; }
      .information .information__wrap .information__left h1 {
        font-family: 'Abhaya Libre', serif;
        font-size: 3.750em;
        line-height: 1em;
        font-weight: 800;
        color: #cf83ce;
        position: relative;
        padding-bottom: 0.88em; }
        .information .information__wrap .information__left h1:after {
          content: "";
          position: absolute;
          top: 0.88em;
          left: 0.10em;
          width: 1.2em;
          height: 0.13em;
          margin-top: 0.25em;
          border-bottom: solid #6b4c76; }
      .information .information__wrap .information__left p {
        font-size: 1.250em;
        line-height: 1.938em;
        max-width: 34.063em;
        font-weight: 400;
        color: #444444;
        text-align: justify; }
    .information .information__wrap .information__photo img {
      display: block;
      margin-left: -100px;
      max-width: 600px;
      max-height: 700px;
      box-shadow: 0.438em 0.438em 0.313em rgba(0, 0, 0, 0.6);
      border-radius: 0.625em; }

@media screen and (max-width: 1024px) {
  .information {
    padding: 50px 0; }
    .information .information__wrap .information__left {
      flex-basis: 50%;
      padding: 30px; }
    .information .information__wrap .information__photo {
      flex-basis: 50%; }
      .information .information__wrap .information__photo img {
        max-width: 100%;
        margin-left: 0; } }

@media screen and (max-width: 960px) {
  .information .information__wrap {
    flex-direction: column; }
    .information .information__wrap .information__left {
      padding: 20px; }
    .information .information__wrap .information__photo {
      margin-top: 30px; } }

@media screen and (max-width: 860px) {
  .information {
    padding: 25px 0; }
    .information .information__wrap .information__left {
      flex-basis: 100%; }
      .information .information__wrap .information__left h2 {
        font-size: 1.2em;
        line-height: 1.5em; }
      .information .information__wrap .information__left h1 {
        font-size: 3.5em; }
    .information .information__wrap .information__photo {
      display: none; } }

@media screen and (max-width: 700px) {
  .information {
    padding: 20px 0; }
    .information .information__wrap {
      margin-top: 0; }
      .information .information__wrap .information__left h2 {
        font-size: 1em;
        line-height: 1em; }
      .information .information__wrap .information__left h1 {
        font-size: 2.5em; }
      .information .information__wrap .information__left p {
        font-size: 1.2em;
        line-height: 1.5em; } }

@media screen and (max-width: 525px) {
  .information {
    padding: 10px 0;
    margin: 0; }
    .information .information__wrap {
      margin-top: 10px; }
      .information .information__wrap .information__left h2 {
        font-size: 0.875em; }
      .information .information__wrap .information__left h1 {
        font-size: 1.5em; }
      .information .information__wrap .information__left p {
        font-size: 1em; } }

@media screen and (max-width: 430px) {
  .information .information__wrap .information__left h1 {
    font-size: 1.4em; }
  .information .information__wrap .information__left p {
    font-size: 0.875em; } }

@media screen and (max-width: 365px) {
  .information .information__wrap .information__left h2 {
    font-size: 0.750em; }
  .information .information__wrap .information__left p {
    font-size: 0.750em; } }

.price .price__wrap {
  background-image: url("../img/price.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  max-width: 100%;
  z-index: 1; }
  .price .price__wrap .devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .price .price__wrap .devider p {
      text-align: center; }
      .price .price__wrap .devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #6b4c76;
        display: block;
        border-radius: 50%; }
    .price .price__wrap .devider::after, .price .price__wrap .devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #cf83ce;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .price .price__wrap .devider::before {
      left: -90px; }
    .price .price__wrap .devider::after {
      right: -90px; }
  .price .price__wrap p {
    font-size: 1em;
    line-height: 1.938em;
    font-weight: lighter;
    color: #444444;
    text-align: justify;
    padding-bottom: 10px;
    padding-left: 5px; }
  .price .price__wrap .price__title {
    display: flex;
    justify-content: center; }
    .price .price__wrap .price__title h1 {
      padding-top: 50px;
      font-family: 'Abhaya Libre', serif;
      font-size: 4.38em;
      line-height: 1em;
      font-weight: 800;
      color: #6b4c76; }
  .price .price__wrap .content__price {
    padding-bottom: 9.375em; }
    .price .price__wrap .content__price .price__content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .price .price__wrap .content__price .price__content .price__link {
        display: flex;
        margin: 1.250em;
        background: #fff;
        width: 18.750em;
        height: 12.500em;
        box-shadow: 0 0 5px;
        border-radius: 0.625em; }
        .price .price__wrap .content__price .price__content .price__link:hover {
          box-shadow: 0.75em 0.75em 0.75em 0.063em rgba(0, 0, 0, 0.5); }
        .price .price__wrap .content__price .price__content .price__link .button__price {
          display: block;
          width: 100%;
          padding: 2.5em;
          justify-content: center;
          font-size: 1.125em;
          font-weight: 400;
          line-height: 1.938em;
          text-decoration: none;
          text-align: center;
          color: #6b4c76; }
          .price .price__wrap .content__price .price__content .price__link .button__price img {
            margin: 0 auto;
            display: block;
            max-width: 100%; }
        .price .price__wrap .content__price .price__content .price__link .overlay {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 10;
          display: none;
          /* фон затемнения */
          background-color: rgba(0, 0, 0, 0.65);
          position: fixed;
          /* фиксированное поцизионирование */
          cursor: default;
          /* тип курсара */ }
        .price .price__wrap .content__price .price__content .price__link .overlay:target {
          display: block; }
        .price .price__wrap .content__price .price__content .price__link .popup {
          top: -100%;
          right: 0;
          left: 50%;
          font-size: 0.875em;
          z-index: 20;
          margin: 0;
          width: 85%;
          min-width: 320px;
          max-width: 1200px;
          /* фиксированное позиционирование, окно стабильно при прокрутке */
          position: absolute;
          padding: 0.938em;
          border: 0.063em solid #383838;
          background: #fefefe;
          /* скругление углов */
          -webkit-border-radius: 0.250em;
          -moz-border-radius: 0.250em;
          -ms-border-radius: 0.250em;
          border-radius: 0.250em;
          /* внешняя тень */
          -webkit-box-shadow: 0 0.938em 1.25em rgba(0, 0, 0, 0.22), 0 1.188em 3.75em rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0 0.938em 1.25em rgba(0, 0, 0, 0.22), 0 1.188em 3.75em rgba(0, 0, 0, 0.3);
          -ms-box-shadow: 0 0.938em 1.25em rgba(0, 0, 0, 0.22), 0 1.188em 3.75em rgba(0, 0, 0, 0.3);
          box-shadow: 0 0.938em 1.25em rgba(0, 0, 0, 0.22), 0 1.188em 3.75em rgba(0, 0, 0, 0.3);
          -webkit-transform: translate(-50%, -500%);
          -ms-transform: translate(-50%, -500%);
          -o-transform: translate(-50%, -500%);
          transform: translate(-50%, -500%);
          -webkit-transition: -webkit-transform 0.6s ease-out;
          -moz-transition: -moz-transform 0.6s ease-out;
          -o-transition: -o-transform 0.6s ease-out;
          transition: transform 0.6s ease-out; }
          .price .price__wrap .content__price .price__content .price__link .popup .title {
            font-size: 1.250em;
            line-height: 1.250em;
            color: #444444;
            font-weight: 400;
            text-align: center;
            margin-bottom: 0.313em; }
          .price .price__wrap .content__price .price__content .price__link .popup table {
            display: flex;
            justify-content: center;
            min-width: 300px;
            max-width: 1200px;
            text-align: left; }
            .price .price__wrap .content__price .price__content .price__link .popup table .first__tr {
              height: 1.250em; }
            .price .price__wrap .content__price .price__content .price__link .popup table tbody {
              width: 100%; }
            .price .price__wrap .content__price .price__content .price__link .popup table tr {
              width: 100%; }
              .price .price__wrap .content__price .price__content .price__link .popup table tr:nth-child(even) {
                background-color: #bfaec1; }
              .price .price__wrap .content__price .price__content .price__link .popup table tr th {
                background-color: #6b4c76;
                color: #fff;
                text-align: left;
                font-size: 1.250em;
                padding: 0.313em 11.250em 0.313em 0; }
              .price .price__wrap .content__price .price__content .price__link .popup table tr td {
                padding-bottom: 0.500em;
                flex-wrap: nowrap;
                width: 100%;
                font-size: 1.250em;
                vertical-align: middle; }
          .price .price__wrap .content__price .price__content .price__link .popup .title__table {
            font-size: 1.000em;
            line-height: 1.000em;
            color: #444444;
            font-weight: 400;
            text-align: center; }
          .price .price__wrap .content__price .price__content .price__link .popup .last__table .first__tr {
            height: 0.313em; }
          .price .price__wrap .content__price .price__content .price__link .popup .last__table tr th {
            font-size: 1.000em; }
          .price .price__wrap .content__price .price__content .price__link .popup .last__table tr td {
            font-size: 1.063em; }
        .price .price__wrap .content__price .price__content .price__link .overlay:target + .popup {
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          top: 32%; }
        .price .price__wrap .content__price .price__content .price__link .close {
          top: -0.625em;
          right: -0.625em;
          width: 1.250em;
          height: 1.250em;
          position: absolute;
          padding: 0;
          border: 0.125em solid #ccc;
          -webkit-border-radius: 0.938em;
          -moz-border-radius: 0.938em;
          -ms-border-radius: 0.938em;
          -o-border-radius: 0.938em;
          border-radius: 0.938em;
          background-color: rgba(61, 61, 61, 0.8);
          -webkit-box-shadow: 0 0 0.625em #000000;
          -moz-box-shadow: 0 0 0.625em #000000;
          box-shadow: 0 0 0.625em #000000;
          text-align: center;
          text-decoration: none;
          font: 0.813em/1.250em 'Tahoma', Arial, sans-serif;
          font-weight: bold;
          -webkit-transition: all ease .8s;
          -moz-transition: all ease .8s;
          -ms-transition: all ease .8s;
          -o-transition: all ease .8s;
          transition: all ease .8s; }
        .price .price__wrap .content__price .price__content .price__link .close:before {
          color: rgba(255, 255, 255, 0.9);
          content: "X";
          text-shadow: 0 -0.063em rgba(0, 0, 0, 0.9);
          font-size: 0.750em; }
        .price .price__wrap .content__price .price__content .price__link .close:hover {
          background-color: #6b4c76;
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg); }

@media screen and (max-width: 1308px) {
  .price .price__wrap .content__price .price__content .price__link .popup table tr th {
    padding: 0.313em 6.000em 0.313em 0; } }

@media screen and (max-width: 1024px) {
  .price .price__wrap .content__price {
    padding-bottom: 6.250em; }
    .price .price__wrap .content__price .price__content .price__link {
      width: 14.375em;
      height: 13.750em;
      margin: 0.625em; } }

@media screen and (max-width: 1002px) {
  .price .price__wrap .content__price .price__content .price__link {
    width: 12.500em; } }

@media screen and (max-width: 960px) {
  .price .price__wrap .price__title h1 {
    padding-top: 30px; } }

@media screen and (max-width: 884px) {
  .price .price__wrap .content__price .price__content .price__link {
    width: 18.750em;
    height: 13.750em; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr th {
      padding: 0.313em 3.000em 0.313em 0; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr .border {
      border-left: 1px solid #222222; } }

@media screen and (max-width: 860px) {
  .price .price__wrap .price__title h1 {
    font-size: 3.5em; } }

@media screen and (max-width: 700px) {
  .price {
    padding: 0; }
    .price .price__wrap p {
      text-align: center;
      font-size: 1em; }
    .price .price__wrap .price__title h1 {
      font-size: 2.5em; }
    .price .price__wrap .content__price {
      padding-bottom: 0; }
      .price .price__wrap .content__price .price__content .price__link {
        width: 14.063em;
        height: 12.500em; }
        .price .price__wrap .content__price .price__content .price__link .overlay:target + .popup {
          top: 15%; }
        .price .price__wrap .content__price .price__content .price__link .popup .title {
          font-size: 1em;
          line-height: 1em; }
        .price .price__wrap .content__price .price__content .price__link .popup table tr th {
          font-size: 1.2em;
          padding: 0.313em 1.000em 0.313em 0; }
        .price .price__wrap .content__price .price__content .price__link .popup table tr td {
          font-size: 1.2em; }
        .price .price__wrap .content__price .price__content .price__link .button__price {
          padding: 1.250em; } }

@media screen and (max-width: 548px) {
  .price .price__wrap p {
    font-size: 0.875em; }
  .price .price__wrap .devider p:after {
    width: 5px;
    height: 5px; }
  .price .price__wrap .devider::after, .price .price__wrap .devider::before {
    width: 60px; }
  .price .price__wrap .devider::before {
    left: -70px; }
  .price .price__wrap .devider::after {
    right: -70px; }
  .price .price__wrap .price__title h1 {
    font-size: 1.7em;
    padding-top: 15px; }
  .price .price__wrap .content__price .price__content .price__link {
    width: 12.500em;
    height: 12.500em; }
    .price .price__wrap .content__price .price__content .price__link .overlay:target + .popup {
      top: 15%; }
    .price .price__wrap .content__price .price__content .price__link .popup .title {
      font-size: 0.875em; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr th {
      font-size: 1em; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr td {
      font-size: 1em; }
    .price .price__wrap .content__price .price__content .price__link .button__price {
      font-size: 1.2em;
      padding: 0.938em; } }

@media screen and (max-width: 450px) {
  .price .price__wrap p {
    padding-bottom: 5px; }
  .price .price__wrap .content__price .price__content .price__link {
    width: 11.250em;
    height: 9.375em; }
    .price .price__wrap .content__price .price__content .price__link .overlay:target + .popup {
      top: 10%; }
    .price .price__wrap .content__price .price__content .price__link .popup .title {
      font-size: 0.875em; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr .first__tr {
      display: none; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr th {
      font-size: 0.875em; }
    .price .price__wrap .content__price .price__content .price__link .popup table tr td {
      font-size: 0.875em; }
    .price .price__wrap .content__price .price__content .price__link .button__price {
      font-size: 1em;
      padding: 0.625em; } }

@media screen and (max-width: 400px) {
  .price .price__wrap p {
    font-size: 0.750em;
    padding-bottom: 0;
    line-height: 16px; }
  .price .price__wrap .price__title h1 {
    padding-top: 10px;
    font-size: 1.4em; }
  .price .price__wrap .content__price .price__content .price__link {
    width: 9.375em;
    height: 10.625em; }
    .price .price__wrap .content__price .price__content .price__link .overlay:target + .popup {
      top: 10%; }
    .price .price__wrap .content__price .price__content .price__link .popup {
      padding: 0.313em; }
      .price .price__wrap .content__price .price__content .price__link .popup .title {
        font-size: 0.875em; }
      .price .price__wrap .content__price .price__content .price__link .popup table tr th {
        font-size: 0.875em; }
      .price .price__wrap .content__price .price__content .price__link .popup table tr td {
        font-size: 0.875em; }
    .price .price__wrap .content__price .price__content .price__link .button__price {
      font-size: 0.875em;
      padding: 0.625em; } }

@media screen and (max-width: 320px) {
  .price .price__wrap .content__price .price__content .price__link {
    width: 7.500em;
    height: 9.375em; }
    .price .price__wrap .content__price .price__content .price__link .close {
      display: none; }
    .price .price__wrap .content__price .price__content .price__link .popup {
      padding: 3px;
      left: 50%;
      width: 280px; }
      .price .price__wrap .content__price .price__content .price__link .popup table {
        min-width: 280px; }
        .price .price__wrap .content__price .price__content .price__link .popup table .first__tr {
          display: none; }
        .price .price__wrap .content__price .price__content .price__link .popup table tr th {
          font-size: 0.875em; } }

.license {
  padding: 0.31em 0;
  background-image: url("../img/licence.jpg");
  background-size: cover;
  display: block; }
  .license .wrap .devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .license .wrap .devider p {
      text-align: center; }
      .license .wrap .devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #6b4c76;
        display: block;
        border-radius: 50%; }
    .license .wrap .devider::after, .license .wrap .devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #cf83ce;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .license .wrap .devider::before {
      left: -90px; }
    .license .wrap .devider::after {
      right: -90px; }
  .license .license__title {
    padding-top: 50px;
    font-family: 'Abhaya Libre', serif;
    text-align: center;
    font-size: 4.38em;
    line-height: 1em;
    font-weight: 800;
    color: #6b4c76; }
  .license .license__block {
    display: flex;
    align-items: center; }
    .license .license__block .license__left {
      position: relative;
      z-index: 1; }
    .license .license__block .qrcode img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 10px; }
  .license .bottom__license {
    margin: 100px auto; }
    .license .bottom__license .license__img {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .license .bottom__license .license__img img {
        display: block;
        max-width: 300px;
        margin: 0 auto;
        transition-property: all;
        transition-duration: 500ms; }
        .license .bottom__license .license__img img:hover {
          -webkit-transform: scale(1.5);
          -moz-transform: scale(1.5);
          transform: scale(1.5); }

@media screen and (max-width: 525px) {
  .license .wrap .devider p:after {
    width: 5px;
    height: 5px; }
  .license .wrap .devider::after, .license .wrap .devider::before {
    width: 60px; }
  .license .wrap .devider::before {
    left: -70px; }
  .license .wrap .devider::after {
    right: -70px; } }

.info__license {
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 30px 50px 30px;
  flex-basis: 100%; }
  .info__license .title {
    font-size: 1.250em;
    color: #cf83ce;
    font-weight: 700; }
  .info__license p {
    font-size: 1.250em;
    line-height: 1.938em;
    font-weight: 400;
    color: #222222;
    text-align: justify; }
  .info__license .license__list {
    padding-left: 20px; }
    .info__license .license__list ul {
      list-style-image: url("/img/tooth.png"); }
      .info__license .license__list ul li {
        font-size: 1.250em;
        line-height: 1.938em;
        font-weight: 400;
        color: #222222;
        text-align: justify; }
  .info__license .last__p {
    color: #444444; }

@media screen and (max-width: 1024px) {
  .license .info__license {
    padding: 30px; }
  .license .bottom__license .license__img img {
    padding: 5px; }
    .license .bottom__license .license__img img:hover {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      transform: scale(1.2); } }

@media screen and (max-width: 960px) {
  .license .license__title {
    padding-top: 30px; }
  .license .bottom__license {
    margin: 50px auto; }
    .license .bottom__license .license__img {
      max-width: 100%; }
      .license .bottom__license .license__img img {
        padding: 35px; }
        .license .bottom__license .license__img img:hover {
          -webkit-transform: scale(1.2);
          -moz-transform: scale(1.2);
          transform: scale(1.2); } }

@media screen and (max-width: 860px) {
  .license .license__title {
    font-size: 3.5em; } }

@media screen and (max-width: 700px) {
  .license .license__title {
    font-size: 2.5em; }
  .license .title {
    font-size: 1.2em; }
  .license p {
    font-size: 1.2em;
    line-height: 1.5em; }
  .license .info__license .license__list li {
    font-size: 1.2em;
    line-height: 1.5em; }
  .license .bottom__license {
    margin: 25px auto; }
    .license .bottom__license .license__img img {
      padding: 25px; } }

@media screen and (max-width: 525px) {
  .license .license__title {
    padding-top: 15px;
    font-size: 1.7em; }
  .license .title {
    font-size: 1em; }
  .license p {
    font-size: 1em; }
  .license .info__license .license__list li {
    font-size: 1em; }
  .license .bottom__license {
    margin: 25px auto; }
    .license .bottom__license .license__img img {
      max-width: 200px;
      padding: 25px; } }

@media screen and (max-width: 430px) {
  .license .license__title {
    padding-top: 10px;
    font-size: 1.4em; }
  .license .title {
    font-size: 0.875em; }
  .license p {
    font-size: 0.875em; }
  .license .info__license .license__list li {
    font-size: 0.875em; } }

@media screen and (max-width: 365px) {
  .license .title {
    font-size: 0.750em; }
  .license p {
    font-size: 0.750em; }
  .license .info__license .license__list li {
    font-size: 0.750em; } }

.services__block {
  background-color: #f4f5f9; }
  .services__block .services__wrap {
    margin: 0 auto;
    max-width: 100%; }
    .services__block .services__wrap .services__title {
      display: flex;
      justify-content: center; }
      .services__block .services__wrap .services__title h1 {
        padding-top: 50px;
        font-family: 'Abhaya Libre', serif;
        font-size: 4.38em;
        line-height: 1em;
        font-weight: 800;
        color: #6b4c76; }
    .services__block .services__wrap .devider {
      display: block;
      width: 7px;
      position: relative;
      margin: 20px auto; }
      .services__block .services__wrap .devider p {
        text-align: center; }
        .services__block .services__wrap .devider p::after {
          content: "";
          width: 7px;
          height: 7px;
          background-color: #6b4c76;
          display: block;
          border-radius: 50%; }
      .services__block .services__wrap .devider::after, .services__block .services__wrap .devider::before {
        content: "";
        width: 80px;
        height: 1px;
        background-color: #cf83ce;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-1px); }
      .services__block .services__wrap .devider::before {
        left: -90px; }
      .services__block .services__wrap .devider::after {
        right: -90px; }
    .services__block .services__wrap .content__block .block__services {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 40px; }
      .services__block .services__wrap .content__block .block__services .services__content {
        flex-basis: 30%;
        padding: 10px;
        position: relative;
        box-sizing: border-box; }
        .services__block .services__wrap .content__block .block__services .services__content .services__img {
          flex-basis: 30%; }
          .services__block .services__wrap .content__block .block__services .services__content .services__img img {
            max-width: 100%;
            display: block; }
        .services__block .services__wrap .content__block .block__services .services__content .services__icon {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          border: 1px solid #cf83ce;
          z-index: 1;
          position: absolute;
          top: 53%;
          left: 5%;
          background-color: #cf83ce;
          -moz-transition: all 900ms ease;
          -webkit-transition: all 900ms ease;
          -ms-transition: all 900ms ease;
          transition: all 900ms ease; }
          .services__block .services__wrap .content__block .block__services .services__content .services__icon:hover {
            background-color: #cf83ce;
            border: 2px solid #cf83ce;
            box-shadow: inset 0 0 0 45px #fff; }
          .services__block .services__wrap .content__block .block__services .services__content .services__icon img {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
        .services__block .services__wrap .content__block .block__services .services__content .caries {
          top: 55%; }
        .services__block .services__wrap .content__block .block__services .services__content .teeth {
          top: 48%; }
        .services__block .services__wrap .content__block .block__services .services__content .services__info {
          position: relative;
          padding-top: 60px; }
          .services__block .services__wrap .content__block .block__services .services__content .services__info h2 {
            font-size: 1.3em;
            line-height: 1.250em;
            color: #444444;
            font-weight: 600;
            padding-top: 10px; }
          .services__block .services__wrap .content__block .block__services .services__content .services__info p {
            flex-basis: 24%;
            font-size: 1em;
            line-height: 1.938em;
            font-weight: lighter;
            color: #444444;
            text-align: justify;
            padding-top: 10px; }

@media screen and (max-width: 1831px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 50%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 52%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 45%; } }

@media screen and (max-width: 1764px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 45%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 49%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 42%; } }

@media screen and (max-width: 1579px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 40%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 45%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 39%; } }

@media screen and (max-width: 1486px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 40%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 42%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 35%; } }

@media screen and (max-width: 1367px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 35%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 38%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 30%; } }

@media screen and (max-width: 1239px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 30%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 35%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 25%; } }

@media screen and (max-width: 1152px) {
  .services__block .services__wrap .content__block .block__services .services__content .services__icon {
    top: 28%; }
  .services__block .services__wrap .content__block .block__services .services__content .caries {
    top: 30%; }
  .services__block .services__wrap .content__block .block__services .services__content .teeth {
    top: 23%; } }

@media screen and (max-width: 1024px) {
  .services__block {
    padding: 50px 0; }
    .services__block .services__wrap .content__block .block__services .services__content .services__icon {
      top: 22%; }
    .services__block .services__wrap .content__block .block__services .services__content .caries {
      top: 28%; }
    .services__block .services__wrap .content__block .block__services .services__content .teeth {
      top: 20%; } }

@media screen and (max-width: 960px) {
  .services__block {
    padding: 50px 0; }
    .services__block .services__wrap .content__block .block__services .services__content .services__icon {
      top: 20%; }
    .services__block .services__wrap .content__block .block__services .services__content .caries {
      top: 25%; }
    .services__block .services__wrap .content__block .block__services .services__content .teeth {
      top: 18%; } }

@media screen and (max-width: 860px) {
  .services__block {
    padding: 50px 0; }
    .services__block .services__wrap .services__title h1 {
      font-size: 3.5em; }
    .services__block .services__wrap .content__block .block__services .services__content {
      flex-basis: 40%; }
      .services__block .services__wrap .content__block .block__services .services__content .services__info {
        padding-top: 20px; }
      .services__block .services__wrap .content__block .block__services .services__content .services__icon {
        display: none; } }

@media screen and (max-width: 768px) {
  .services__block {
    padding: 20px 0; } }

@media screen and (max-width: 700px) {
  .services__block {
    padding: 20px 0; }
    .services__block .services__wrap .content__block .block__services .services__content .services__info p {
      line-height: 1.5em; }
    .services__block .services__wrap .services__title h1 {
      padding-top: 15px;
      font-size: 2.5em; } }

@media screen and (max-width: 525px) {
  .services__block {
    padding: 20px 0; }
    .services__block .services__wrap .content__block .block__services .services__content {
      flex-basis: 100%; }
      .services__block .services__wrap .content__block .block__services .services__content .services__info {
        padding-top: 10px; }
        .services__block .services__wrap .content__block .block__services .services__content .services__info h2 {
          font-size: 1.1em; }
    .services__block .services__wrap .services__title h1 {
      padding-top: 15px;
      font-size: 1.7em; } }

@media screen and (max-width: 430px) {
  .services__block {
    padding: 10px 0; }
    .services__block .services__wrap .devider p:after {
      width: 5px;
      height: 5px; }
    .services__block .services__wrap .devider::after, .services__block .services__wrap .devider::before {
      width: 60px; }
    .services__block .services__wrap .devider::before {
      left: -70px; }
    .services__block .services__wrap .devider::after {
      right: -70px; }
    .services__block .services__wrap .content__block .block__services .services__content {
      flex-basis: 100%; }
      .services__block .services__wrap .content__block .block__services .services__content .services__info {
        padding-top: 5px; }
        .services__block .services__wrap .content__block .block__services .services__content .services__info h2 {
          font-size: 1em;
          padding-top: 5px; }
        .services__block .services__wrap .content__block .block__services .services__content .services__info p {
          font-size: 0.875em;
          padding-top: 5px; }
    .services__block .services__wrap .services__title h1 {
      padding-top: 10px;
      font-size: 1.4em; } }

@media screen and (max-width: 365px) {
  .services__block {
    padding: 0; }
    .services__block .services__wrap .content__block .block__services .services__content .services__info h2 {
      font-size: 0.750em;
      padding-top: 5px; }
    .services__block .services__wrap .content__block .block__services .services__content .services__info p {
      font-size: 0.750em;
      padding-top: 5px; }
    .services__block .services__wrap .services__title h1 {
      padding-top: 10px;
      font-size: 1.4em; } }

.contact__block {
  background-color: #6b4c76; }
  .contact__block .contact__wrap {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box; }
    .contact__block .contact__wrap .contact__left {
      padding: 40px 0;
      margin: 0 auto; }
      .contact__block .contact__wrap .contact__left img {
        max-width: 100%;
        display: block; }
    .contact__block .contact__wrap .contact__right {
      margin: 0 auto;
      padding: 50px 0; }
      .contact__block .contact__wrap .contact__right h2 {
        position: relative;
        font-family: "Abhaya Libre";
        font-size: 35px;
        color: #fff;
        font-weight: 700;
        line-height: 40px;
        padding-bottom: 10px; }
        .contact__block .contact__wrap .contact__right h2:after {
          content: "";
          position: absolute;
          top: 80%;
          right: 50%;
          width: 5.000em;
          height: 0.13em;
          border-bottom: 2px solid #cf83ce; }
      .contact__block .contact__wrap .contact__right .contact__address {
        padding: 15px 0; }
        .contact__block .contact__wrap .contact__right .contact__address .contact__info {
          color: #fff;
          font-size: 20px;
          line-height: 16px;
          padding-bottom: 10px; }
        .contact__block .contact__wrap .contact__right .contact__address P {
          font-size: 1.250em;
          line-height: 25px;
          font-weight: 400;
          color: #fff;
          text-align: justify; }
        .contact__block .contact__wrap .contact__right .contact__address a {
          font-size: 1.250em;
          line-height: 25px;
          font-weight: 400;
          color: #fff;
          text-align: justify;
          text-decoration: none; }

@media scren and (max-width: 1900px) {
  .contact__block .contact__wrap .contact__left {
    padding: 40px 120px; }
  .contact__block .contact__wrap .contact__right {
    padding-top: 40px;
    padding-left: 10px; }
    .contact__block .contact__wrap .contact__right h2:after {
      left: 0; } }

@media screen and (max-width: 1024px) {
  .contact__block .contact__wrap .contact__left {
    padding: 40px; }
  .contact__block .contact__wrap .contact__right {
    padding-top: 40px;
    padding-left: 10px; }
    .contact__block .contact__wrap .contact__right h2:after {
      left: 0; } }

@media screen and (max-width: 960px) {
  .contact__block .contact__wrap .contact__left {
    padding: 20px; }
  .contact__block .contact__wrap .contact__right {
    padding-top: 20px; } }

@media screen and (max-width: 860px) {
  .contact__block .contact__wrap .contact__left {
    padding: 40px 20px; }
    .contact__block .contact__wrap .contact__left img {
      max-width: 500px; }
  .contact__block .contact__wrap .contact__right {
    padding-top: 30px;
    padding-left: 10px; }
    .contact__block .contact__wrap .contact__right h2 {
      font-size: 1.3em;
      line-height: 1.5em; }
      .contact__block .contact__wrap .contact__right h2:after {
        left: 0; }
    .contact__block .contact__wrap .contact__right .contact__address {
      padding: 10px 0; }
      .contact__block .contact__wrap .contact__right .contact__address .contact__info {
        font-size: 1em; }
      .contact__block .contact__wrap .contact__right .contact__address p {
        font-size: 1em; }
      .contact__block .contact__wrap .contact__right .contact__address a {
        font-size: 1em; } }

@media screen and (max-width: 792px) {
  .contact__block .contact__wrap .contact__left {
    padding: 40px 10px; }
    .contact__block .contact__wrap .contact__left img {
      max-width: 450px; } }

@media screen and (max-width: 700px) {
  .contact__block .contact__wrap {
    flex-direction: column; }
    .contact__block .contact__wrap .contact__left {
      margin: 0 auto;
      padding: 40px 10px 20px 10px; }
      .contact__block .contact__wrap .contact__left img {
        max-width: 100%; }
    .contact__block .contact__wrap .contact__right {
      padding-top: 10px;
      padding-left: 0;
      margin: 0 auto; }
      .contact__block .contact__wrap .contact__right .contact__address {
        padding: 10px 0; } }

@media screen and (max-width: 430px) {
  .contact__block .contact__wrap .contact__left {
    padding: 20px 5px 10px 5px; }
  .contact__block .contact__wrap .contact__right {
    padding-top: 5px;
    padding-left: 0;
    margin: 0 auto; }
    .contact__block .contact__wrap .contact__right h2 {
      font-size: 1em; }
    .contact__block .contact__wrap .contact__right .contact__address {
      padding: 10px 0; }
      .contact__block .contact__wrap .contact__right .contact__address .contact__info {
        padding-bottom: 5px;
        font-size: 1em; }
      .contact__block .contact__wrap .contact__right .contact__address p {
        font-size: 0.875em; }
      .contact__block .contact__wrap .contact__right .contact__address a {
        font-size: 0.875em; } }

@media screen and (max-width: 365px) {
  .contact__block .contact__wrap .contact__right {
    padding-bottom: 5px; }
    .contact__block .contact__wrap .contact__right .contact__address {
      padding: 6px 0; }
      .contact__block .contact__wrap .contact__right .contact__address .contact__info {
        font-size: 0.875em; }
      .contact__block .contact__wrap .contact__right .contact__address p {
        font-size: 0.750em; }
      .contact__block .contact__wrap .contact__right .contact__address a {
        font-size: 0.750em; } }

.footer {
  background-color: #e6e6e8; }
  .footer .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .footer .wrap .footer__img {
      padding: 15px; }
      .footer .wrap .footer__img img {
        display: block;
        max-width: 200px; }
    .footer .wrap p {
      padding: 40px;
      font-size: 1.000em;
      color: #444444; }

@media screen and (max-width: 525px) {
  .footer .wrap .footer__img img {
    max-width: 180px; }
  .footer .wrap p {
    font-size: 0.875em;
    padding: 40px 0; } }

@media screen and (max-width: 525px) {
  .footer .wrap {
    flex-direction: column;
    text-align: center; }
    .footer .wrap .footer__img {
      margin: 0 auto;
      padding: 0; }
      .footer .wrap .footer__img img {
        max-width: 180px; }
    .footer .wrap p {
      font-size: 0.750em;
      padding: 5px 0; } }

.buttons {
  margin-top: 60px; }
  .buttons a {
    width: 225px;
    border-radius: 25px;
    background-color: #cf83ce;
    border: 2px solid #cf83ce;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    padding: 18px 0;
    letter-spacing: 0.14px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
    box-sizing: border-box;
    transition-duration: 500ms;
    transition-property: background-color, border-color; }
    .buttons a:hover {
      background-color: #4f3857;
      border-color: #4f3857; }
    .buttons a:first-child {
      margin-right: 25px; }
    .buttons a:last-child {
      background-color: transparent;
      border-color: #fff; }
    .buttons a:hover:last-child {
      background-color: #6b4c76;
      border-color: #6b4c76;
      color: #fff; }
    .buttons a:hover:first-child {
      color: #fff; }

@media screen and (max-width: 960px) {
  .buttons {
    margin-top: 35px; } }

@media screen and (max-width: 860px) {
  .buttons {
    margin-top: 20px; }
    .buttons a {
      width: 150px;
      padding: 10px 0; } }

@media screen and (max-width: 768px) {
  .buttons {
    margin-top: 10px; }
    .buttons a {
      width: 120px;
      padding: 10px 0; } }

@media screen and (max-width: 586px) {
  .buttons {
    margin-top: 8px; }
    .buttons a {
      width: 100px;
      padding: 2px 0;
      font-size: 12px; } }

.staff .wrap {
  background-image: url("../img/staff.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  max-width: 100%;
  z-index: 1;
  padding-bottom: 100px; }
  .staff .wrap .staff__title {
    display: flex;
    justify-content: center; }
    .staff .wrap .staff__title h1 {
      padding-top: 50px;
      font-family: 'Abhaya Libre', serif;
      font-size: 4.38em;
      line-height: 1em;
      font-weight: 800;
      color: #6b4c76; }
  .staff .wrap .devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .staff .wrap .devider p {
      text-align: center; }
      .staff .wrap .devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #6b4c76;
        display: block;
        border-radius: 50%; }
    .staff .wrap .devider::after, .staff .wrap .devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #cf83ce;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .staff .wrap .devider::before {
      left: -90px; }
    .staff .wrap .devider::after {
      right: -90px; }
  .staff .wrap .staff__block .staff__wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .staff .wrap .staff__block .staff__wrap .staff__info {
      display: flex;
      margin: 1.250em;
      background: #fff;
      box-shadow: 0 0 5px;
      border-radius: 0.625em;
      padding: 10px; }
      .staff .wrap .staff__block .staff__wrap .staff__info a {
        text-decoration: none; }
      .staff .wrap .staff__block .staff__wrap .staff__info .title {
        font-size: 1.250em;
        line-height: 1.250em;
        color: #6b4c76;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0.313em; }
      .staff .wrap .staff__block .staff__wrap .staff__info p {
        font-size: 1.125em;
        line-height: 25px;
        max-width: 34.063em;
        font-weight: 400;
        color: #444444;
        text-align: center;
        padding: 2px; }
      .staff .wrap .staff__block .staff__wrap .staff__info:hover {
        box-shadow: 0.75em 0.75em 0.75em 0.063em rgba(0, 0, 0, 0.5); }

@media screen and (max-width: 1024px) {
  .staff .wrap {
    padding-bottom: 50px; } }

@media screen and (max-width: 960px) {
  .staff .wrap .staff__title h1 {
    padding-top: 30px; } }

@media screen and (max-width: 860px) {
  .staff .wrap .staff__title h1 {
    font-size: 3.5em; } }

@media screen and (max-width: 700px) {
  .staff .wrap {
    padding-bottom: 20px; }
    .staff .wrap .staff__title h1 {
      font-size: 2.5em; }
    .staff .wrap .staff__block .staff__wrap .staff__info {
      margin: 10px; }
      .staff .wrap .staff__block .staff__wrap .staff__info p {
        font-size: 1.2em;
        line-height: 1.5em; } }

@media screen and (max-width: 525px) {
  .staff .wrap .staff__title h1 {
    font-size: 1.7em;
    padding-top: 15px; }
  .staff .wrap .devider p:after {
    width: 5px;
    height: 5px; }
  .staff .wrap .devider::after, .staff .wrap .devider::before {
    width: 60px; }
  .staff .wrap .devider::before {
    left: -70px; }
  .staff .wrap .devider::after {
    right: -70px; }
  .staff .wrap .staff__block .staff__wrap .staff__info {
    margin: 5px; }
    .staff .wrap .staff__block .staff__wrap .staff__info .title {
      font-size: 1em; }
    .staff .wrap .staff__block .staff__wrap .staff__info p {
      padding: 0;
      font-size: 1em; } }

@media screen and (max-width: 400px) {
  .staff .wrap .staff__title h1 {
    font-size: 1.4em;
    padding-top: 10px; }
  .staff .wrap .staff__block .staff__wrap .staff__info .title {
    font-size: 1em; }
  .staff .wrap .staff__block .staff__wrap .staff__info p {
    font-size: 0.875em; } }

@media screen and (max-width: 365px) {
  .staff .wrap {
    padding-bottom: 5px; }
    .staff .wrap .staff__block .staff__wrap .staff__info .title {
      font-size: 0.750em; }
    .staff .wrap .staff__block .staff__wrap .staff__info p {
      font-size: 0.750em; } }

.specialist {
  background-image: url("../img/specialist.jpg");
  background-size: cover;
  display: block;
  max-width: 100%;
  z-index: 1;
  padding-bottom: 50px; }
  .specialist .wrap .specialist__title {
    display: flex;
    justify-content: center; }
    .specialist .wrap .specialist__title h1 {
      padding-top: 50px;
      font-family: 'Abhaya Libre', serif;
      font-size: 4.38em;
      line-height: 1em;
      font-weight: 800;
      color: #6b4c76; }
  .specialist .wrap .devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .specialist .wrap .devider p {
      text-align: center; }
      .specialist .wrap .devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #6b4c76;
        display: block;
        border-radius: 50%; }
    .specialist .wrap .devider::after, .specialist .wrap .devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #cf83ce;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .specialist .wrap .devider::before {
      left: -90px; }
    .specialist .wrap .devider::after {
      right: -90px; }
  .specialist .wrap .specialist__wrap {
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 30px 50px 80px;
    flex-basis: 100%; }
    .specialist .wrap .specialist__wrap .specialist__block .block__info {
      padding: 10px 0; }
      .specialist .wrap .specialist__wrap .specialist__block .block__info h2 {
        font-size: 1.250em;
        color: #cf83ce;
        font-weight: 700;
        padding-bottom: 3px; }
      .specialist .wrap .specialist__wrap .specialist__block .block__info p {
        font-size: 1.250em;
        line-height: 1.938em;
        font-weight: 400;
        color: #222222;
        text-align: justify; }

@media screen and (max-width: 1024px) {
  .specialist .wrap .specialist__wrap {
    padding: 30px; } }

@media screen and (max-width: 960px) {
  .specialist .wrap .specialist__title h1 {
    padding-top: 30px; } }

@media screen and (max-width: 860px) {
  .specialist .wrap .specialist__title h1 {
    font-size: 3.5em; } }

@media screen and (max-width: 700px) {
  .specialist .wrap .specialist__title h1 {
    font-size: 2.5em; }
  .specialist .wrap .specialist__wrap .specialist__block .block__info {
    padding: 5px 0; }
    .specialist .wrap .specialist__wrap .specialist__block .block__info h2 {
      font-size: 1.2em; }
    .specialist .wrap .specialist__wrap .specialist__block .block__info p {
      font-size: 1.2em;
      line-height: 1.5em; } }

@media screen and (max-width: 525px) {
  .specialist {
    padding-bottom: 30px; }
    .specialist .wrap .specialist__title h1 {
      font-size: 1.7em;
      padding-top: 15px; }
    .specialist .wrap .devider p:after {
      width: 5px;
      height: 5px; }
    .specialist .wrap .devider::after, .specialist .wrap .devider::before {
      width: 60px; }
    .specialist .wrap .devider::before {
      left: -70px; }
    .specialist .wrap .devider::after {
      right: -70px; }
    .specialist .wrap .specialist__wrap .specialist__block .block__info {
      padding: 5px 0; }
      .specialist .wrap .specialist__wrap .specialist__block .block__info h2 {
        font-size: 1em; }
      .specialist .wrap .specialist__wrap .specialist__block .block__info p {
        font-size: 1em; } }

@media screen and (max-width: 400px) {
  .specialist .wrap .specialist__title h1 {
    font-size: 1.4em;
    padding-top: 10px; }
  .specialist .wrap .specialist__wrap .specialist__block .block__info h2 {
    font-size: 0.875em; }
  .specialist .wrap .specialist__wrap .specialist__block .block__info p {
    font-size: 0.875em; } }

@media screen and (max-width: 365px) {
  .specialist {
    padding-bottom: 10px; }
    .specialist .wrap .specialist__wrap .specialist__block .block__info h2 {
      font-size: 0.750em; }
    .specialist .wrap .specialist__wrap .specialist__block .block__info p {
      font-size: 0.750em; } }

.logo_russia p {
  color: #6b4c76;
  font-size: 40px;
  font-family: Quicksand, sans-serif;
  font-weight: 800;
  padding: 15px 15px 0px 15px;
  min-width: 320px;
  max-width: 1200px;
  box-sizing: border-box;
  margin: 0 auto; }

@media screen and (max-width: 1024px) {
  .logo_russia p {
    text-align: center; } }
