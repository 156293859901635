
  .slider__item {

    .slider__img {
      position: relative;
      background-size: cover;
      margin: 0 auto;
      z-index: 1;

      img {
        display: block;
        width: 100%;
      }
    }

    .slider__info {
      position: absolute;
      left: 8.1em;
      top: 10em;
      z-index: 2;

      .info {
        max-width: 45em;
        font-size: 1.3em;
        line-height: 1.9em;
        font-weight: 400;
        color: $dark-gray;
      }

      h2 {
        font-size: 1.6em;
        line-height: 2.3em;
        contain: $dark-gray;
        font-weight: 400;
        padding-bottom: 1.9em;
      }

      .slider__title {
        max-width: 100%;
        position: relative;
        font-family: 'Abhaya Libre', serif;
        font-size: 4.38em;
        line-height: 1em;
        font-weight: 800;
        color: $line-menu;
        padding-bottom: 0.88em;

        &:after {
          content: "";
          position: absolute;
          top: 0.88em;
          left: 0.10em;
          width: 1.2em;
          height: 0.13em;
          margin-top: 0.25em;
          border-bottom: solid $slider;

        }
      }
    }
  }

.owl-nav {
  button.owl-prev {
    background: url("../../img/back.png") 0 0 no-repeat;
    width: 3.063em;
    height: 3.063em;
    border: none;
    box-shadow: none;
    position: absolute;
    left: 0.313em;
    top: 50%;
    cursor: pointer;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    &:focus {
      outline: none;
    }
  }

  button.owl-next {
    background: url("../../img/next.png") 0 0 no-repeat;
    width: 3.063em;
    height: 3.063em;
    border: none;
    box-shadow: none;
    position: absolute;
    right: 0.313em;
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);

    &:focus {
      outline: none;
    }
  }

  .owl-dots {
    display: none;
  }
}

  @media screen and (max-width: 1540px) {
    .slider__item {

      .slider__info {
        left: 5.5em;
        top: 70px;
      }
    }
  }

  @media screen and (max-width: 1140px) {
    .slider__item {
      .slider__info {
        left: 5.5em;
        top: 40px;

        h2 {
          padding-bottom: 20px;
        }
      }
    }
  }

@media screen and (max-width: 1024px) {

  .slider__item {

    .slider__info {
      left: 5.5em;
      top: 20px;

      .slider__title {
        padding-bottom: 0.625em;
      }

      .buttons {
        margin-top: 45px;
      }
    }

  }
}


@media screen and (max-width: 960px) {

  .slider {

    .slider__item {

      .slider__info {
        left: 1em;
        top: 15px;

        .buttons {
          margin-top: 32px;
        }

        h2 {
          padding-bottom: 10px;
        }

        .info {
          padding-top: 30px;
        }

        .slider__title {
          padding-bottom: 2px;
        }
      }
    }

    .owl-dots {
      text-align: center;
      position: absolute;
      bottom:5%;
      left: 50%;

      .owl-dot {
        width: 0.625em;
        height: 0.625em;
        border-radius: 0.625em;
        background-color: $white;
        box-sizing: border-box;
        border: none;
        box-shadow: none;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      .active {
        background-color: $line-menu;
      }
    }

    .owl-nav {
      display: none;
    }
  }
}

@media screen and (max-width: 860px) {

  .slider{

    .slider__item {

      .slider__info {

        .info {
          color: $black;
          padding-top: 15px;
        }

        .h2 {
          font-size: 1.2em;
          color: $dark-black;
          line-height: 1.5em;
          padding-bottom: 1.5em;
        }

        .slider__title {
          font-size: 3.5em;
          color: $line-menu;
        }
      }
    }
  }
}

  @media screen and (max-width: 768px){

    .slider {
      .slider__item {

        .slider__info {
          top:10px;

          h2 {
            font-size: 1.2em;
          }
          .slider__title {
            padding-bottom: 15px;
          }

          .info {
            font-size: 1.250em;
            padding-top: 5px;
          }

          .buttons {
            margin-top: 10px;
          }
        }

      }
    }

  }

@media screen and (max-width: 700px) {
  .slider {

    .slider__item {
      padding: 0;

      .slider__info {
        top: 15px;

        .slider__title {
          font-size: 2.5em;
        }

        h2 {
          font-size: 1em;
          line-height: 5px;
        }

        .info {
          max-width: 100%;
          text-align: justify;
          font-size: 1.2em;
          line-height: 1.5em;
          padding-right: 5px;
        }
      }
    }
  }
}


  @media screen and (max-width: 525px) {
    .slider {

      .slider__item {

        .slider__info {

          h2 {
            font-size: 0.875em;
          }
          .slider__title {
            font-size: 1.5em;
          }

          .info {
            font-size: 1em;
            font-weight: 400;

          }
        }
      }
    }
  }

  @media screen and (max-width: 430px) {
    .slider {

      .slider__item {

        .slider__info {

          .slider__title {
            font-size: 1.4em;
          }

          .info {
            font-size: 0.875em;

          }
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .slider {

      .slider__info {
        top: 15px;
      }
    }
  }

  @media screen and (max-width: 365px) {
    .slider {

      .owl-dots {
          .owl-dot {
            width: 0.313em;
            height: 0.313em;
            border-radius: 0.313em;
          }
      }

      .slider__item {

        .slider__info {

          h2 {
            font-size: 0.750em;
          }

          .info {
            font-size: 0.750em;
            padding: 0;
          }

          .slider__title {
            font-size: 1.2em;
          }
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    .slider {

      .slider__item {

        .slider__info {
          top: 0.88em;
        }
      }
    }
  }

