.footer {
  background-color: $bg-price;

  .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer__img {
      padding: 15px;

      img {
        display: block;
        max-width: 200px;
      }
    }

    p {
      padding: 40px;
      font-size: 1.000em;
      color: $dark-gray;
    }
  }
}


@media screen and (max-width: 525px) {
  .footer {

    .wrap {

      .footer__img {

        img {
          max-width: 180px;
        }
      }

      p {
        font-size: 0.875em;
        padding: 40px 0;
      }

    }
  }

}

@media screen and (max-width: 525px) {
  .footer {

    .wrap {
      flex-direction: column;
      text-align: center;

      .footer__img {
        margin: 0 auto;
        padding: 0;

        img {
          max-width: 180px;
        }
      }

      p {
        font-size: 0.750em;
        padding: 5px 0;
      }

    }
  }

}