.contact__block {
  background-color: $line-menu;

  .contact__wrap {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .contact__left {
      padding: 40px 0;
      margin: 0 auto;

      img {
        max-width: 100%;
        display: block;
      }
    }

    .contact__right {
      margin: 0 auto;
      padding: 50px 0;

      h2 {
        position: relative;
        font-family: "Abhaya Libre";
        font-size: 35px;
        color: $white;
        font-weight: 700;
        line-height: 40px;
        padding-bottom: 10px;

        &:after {
          content: "";
          position: absolute;
          top: 80%;
          right: 50%;
          width: 5.000em;
          height: 0.13em;
          border-bottom: 2px solid $slider;
        }
      }

      .contact__address {
        padding: 15px 0;

        .contact__info {
          color: $white;
          font-size: 20px;
          line-height: 16px;
          padding-bottom: 10px;
        }

        P {
          font-size: 1.250em;
          line-height: 25px;
          font-weight: 400;
          color: $white;
          text-align: justify;
        }

        a {
          font-size: 1.250em;
          line-height: 25px;
          font-weight: 400;
          color: $white;
          text-align: justify;
          text-decoration: none;
        }
      }
    }
  }
}

@media scren and (max-width: 1900px) {
  .contact__block {

    .contact__wrap {

      .contact__left {
        padding: 40px 120px;
      }

      .contact__right {
        padding-top: 40px;
        padding-left: 10px;

        h2 {

          &:after {
            left: 0;
          }
        }
      }
    }
  }
}



@media screen and (max-width: 1024px) {

  .contact__block {

    .contact__wrap {

      .contact__left {
        padding: 40px;
      }

      .contact__right {
        padding-top: 40px;
        padding-left: 10px;

        h2 {

          &:after {
            left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {

  .contact__block {

    .contact__wrap {

      .contact__left {
        padding: 20px;
      }

      .contact__right {
        padding-top: 20px;

      }
    }
  }
}

@media screen and (max-width: 860px) {

  .contact__block {

    .contact__wrap {


      .contact__left {
        padding: 40px 20px;

        img {
          max-width: 500px;
        }
      }

      .contact__right {
        padding-top: 30px;
        padding-left: 10px;

        h2 {
          font-size: 1.3em;
          line-height: 1.5em;

          &:after {
            left: 0;
          }
        }

        .contact__address {
          padding: 10px 0;

          .contact__info {
            font-size: 1em;
          }

          p {
            font-size: 1em;
          }

          a {
            font-size: 1em;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 792px) {

  .contact__block {

    .contact__wrap {

      .contact__left {
        padding: 40px 10px;

        img {
          max-width: 450px;
        }
      }

      }
    }
  }

@media screen and (max-width: 700px) {

  .contact__block {

    .contact__wrap {
      flex-direction: column;

      .contact__left {
        margin: 0 auto;
        padding: 40px 10px 20px 10px;

        img {
          max-width: 100%;
        }
      }

      .contact__right {
        padding-top: 10px;
        padding-left: 0;
        margin: 0 auto;


        .contact__address {
          padding: 10px 0;

        }

      }
    }
  }
}

@media screen and (max-width: 430px) {

  .contact__block {

    .contact__wrap {

      .contact__left {
        padding: 20px 5px 10px 5px;
      }

      .contact__right {
        padding-top: 5px;
        padding-left: 0;
        margin: 0 auto;

        h2 {
          font-size: 1em;
        }

        .contact__address {
          padding: 10px 0;

          .contact__info {
            padding-bottom: 5px;
            font-size: 1em;
          }

          p {
            font-size: 0.875em;
          }

          a {
            font-size: 0.875em;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 365px) {

  .contact__block {

    .contact__wrap {

      .contact__right {
        padding-bottom: 5px;

        .contact__address {
          padding: 6px 0;

          .contact__info {
            font-size: 0.875em;
          }

          p {
            font-size: 0.750em;
          }

          a {
            font-size: 0.750em;
          }
        }
      }
    }
  }
}



