.services__block {
  
  background-color: $white-gray;

  .services__wrap {
    margin: 0 auto;
    max-width: 100%;

    .services__title {
      display: flex;
      justify-content: center;


      h1 {
        padding-top: 50px;
        font-family: 'Abhaya Libre', serif;
        font-size: 4.38em;
        line-height: 1em;
        font-weight: 800;
        color: $line-menu;
      }
    }

       .devider {
         @include devider
       }

      .content__block {

        .block__services{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-bottom: 40px;

          .services__content {
            flex-basis: 30%;
            padding: 10px;
            position: relative;
            box-sizing: border-box;

            .services__img {
              flex-basis: 30%;

              img {
                max-width: 100%;
                display: block;
              }
            }

            .services__icon {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              border: 1px solid $slider;
              z-index: 1;
              position: absolute;
              top: 53%;
              left: 5%;
              background-color: $slider;
              -moz-transition: all 900ms ease;
              -webkit-transition: all 900ms ease;
              -ms-transition: all 900ms ease;
              transition: all 900ms ease;

              &:hover {
                background-color: $slider;
                border: 2px solid $slider;
                box-shadow: inset 0 0 0 45px $white;
              }


              img {
                width: 60px;
                height: 60px;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .caries {
              top: 55%;
            }
            .teeth {
              top: 48%;
            }

            .services__info {
              position: relative;
              padding-top: 60px;

              h2 {
                font-size: 1.3em;
                line-height: 1.250em;
                color: $dark-gray;
                font-weight: 600;
                padding-top: 10px;
              }

              p {
                flex-basis: 24%;
                font-size: 1em;
                line-height: 1.938em;
                font-weight: lighter;
                color: $dark-gray;
                text-align: justify;
                padding-top: 10px;
              }
            }
          }
        }
      }
  }
}

@media screen and (max-width: 1831px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 50%;
            }

            .caries {
              top: 52%;
            }

            .teeth {
              top: 45%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1764px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 45%;
            }

            .caries {
              top: 49%;
            }

            .teeth {
              top: 42%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1579px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 40%;
            }

            .caries {
              top: 45%;
            }

            .teeth {
              top: 39%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1486px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 40%;
            }

            .caries {
              top: 42%;
            }

            .teeth {
              top: 35%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1367px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 35%;
            }

            .caries {
              top: 38%;
            }

            .teeth {
              top: 30%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1239px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 30%;
            }

            .caries {
              top: 35%;
            }

            .teeth {
              top: 25%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1152px) {

  .services__block {

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 28%;
            }

            .caries {
              top: 30%;
            }

            .teeth {
              top: 23%;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {

  .services__block {
    padding: 50px 0;

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 22%;
            }

            .caries {
              top: 28%;
            }

            .teeth {
              top: 20%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {

  .services__block {
    padding: 50px 0;

    .services__wrap {

      .content__block {

        .block__services{

          .services__content {

            .services__icon {
              top: 20%;
            }

            .caries {
              top: 25%;
            }

            .teeth {
              top: 18%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {

  .services__block {
    padding: 50px 0;

    .services__wrap {

      .services__title {
        h1 {
          font-size: 3.5em;
        }
      }

      .content__block {

        .block__services{

          .services__content {
            flex-basis: 40%;

            .services__info {
              padding-top: 20px;
            }

            .services__icon {
              display: none;
            }

          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services__block {
    padding: 20px 0;
  }
}

@media screen and (max-width: 700px) {

  .services__block {
    padding: 20px 0;

    .services__wrap {

      .content__block {

        .block__services {

          .services__content {

            .services__info {

              p {
                line-height: 1.5em;
              }
            }
          }
        }
      }

      .services__title {
        h1 {
          padding-top: 15px;
          font-size: 2.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {

  .services__block {
    padding: 20px 0;

    .services__wrap {

      .content__block {

        .block__services {

          .services__content {
            flex-basis: 100%;

            .services__info {
              padding-top: 10px;

              h2 {
                font-size: 1.1em;
              }
            }
          }
        }
      }
      .services__title {
        h1 {
          padding-top: 15px;
          font-size: 1.7em;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {

  .services__block {
    padding: 10px 0;

    .services__wrap {

      .devider {

        p {

          &:after {
            width: 5px;
            height: 5px;
          }
        }

        &::after, &::before {
          width: 60px;
        }

        &::before {
          left: -70px;

        }

        &::after {
          right: -70px;
        }
      }

      .content__block {

        .block__services {

          .services__content {
            flex-basis: 100%;

            .services__info {
              padding-top: 5px;

              h2 {
                font-size: 1em;
                padding-top: 5px;
              }

              p {
                font-size: 0.875em;
                padding-top: 5px;
              }
            }
          }
        }
      }
      .services__title {
        h1 {
          padding-top: 10px;
          font-size: 1.4em;
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {

  .services__block {
    padding: 0;

    .services__wrap {

      .content__block {

        .block__services {

          .services__content {

            .services__info {

              h2 {
                font-size: 0.750em;
                padding-top: 5px;
              }

              p {
                font-size: 0.750em;
                padding-top: 5px;
              }
            }
          }
        }
      }
      .services__title {
        h1 {
          padding-top: 10px;
          font-size: 1.4em;
        }
      }
    }
  }
}





