.buttons {
  margin-top: 60px;

  a {
    @include btn($bg: $slider, $two-button: true);
  }

}

@media screen and (max-width: 960px) {

  .buttons {
    margin-top: 35px;
  }
}

@media screen and (max-width: 860px) {

  .buttons {
    margin-top: 20px;

    a {
      width: 150px;
      padding: 10px 0;
    }
  }
}

@media screen and (max-width: 768px) {

  .buttons {
    margin-top: 10px;

    a {
      width: 120px;
      padding: 10px 0;
    }
  }
}

@media screen and (max-width: 586px) {

  .buttons {
    margin-top: 8px;

    a {
      width: 100px;
      padding: 2px 0;
      font-size: 12px;
    }
  }
}


