.license {
  padding: 0.31em 0;
  background-image: url("../img/licence.jpg");
  background-size: cover;
  display: block;

  .wrap {

    .devider {
      @include devider()
    }
  }

  .license__title {
    padding-top: 50px;
    font-family: 'Abhaya Libre', serif;
    text-align: center;
    font-size: 4.38em;
    line-height: 1em;
    font-weight: 800;
    color: $line-menu;
  }

  .license__block {
    display: flex;
    align-items: center;

    .license__left {
      position: relative;
      z-index: 1;
    }

    .qrcode {
      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 10px;
      }  
    }
  }

  .bottom__license {
    margin: 100px auto;

    .license__img {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      img {
        display: block;
        max-width: 300px;
        margin: 0 auto;
        transition-property: all;
        transition-duration: 500ms;

        &:hover {
          -webkit-transform: scale(1.5);
          -moz-transform: scale(1.5);
          transform: scale(1.5);

        }
      }
    }
  }
}

@media screen and (max-width: 525px){

  .license {

    .wrap {

      .devider {

        p {

          &:after {
            width: 5px;
            height: 5px;
          }
        }

        &::after, &::before {
          width: 60px;
        }

        &::before {
          left: -70px;

        }

        &::after {
          right: -70px;
        }
      }
    }
  }

}



