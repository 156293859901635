.logo_russia {
    p {
        color: $line-menu;
        font-size: 40px;
        font-family: Quicksand, sans-serif;
        font-weight: 800;
        padding: 15px 15px 0px 15px;
        min-width: 320px;
        max-width: 1200px;
        box-sizing: border-box;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1024px) {
    .logo_russia {
        
        p {
            text-align: center;
        }
    }
}