
.top__menu {
  border-bottom: 0.13em solid $top-line;

  .menu {

    #menu__toggle {
      opacity: 0;
    }

    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    li {
      text-align: center;
      padding-right: 2.2em;
    }

    a {
      font-size: 0.88em;
      line-height: 3.4em;
      color: $dark-gray;
      text-decoration: none;
      text-transform: uppercase;
      padding-bottom: 1em;
      border-bottom: 0.19em solid transparent;
      -webkit-duration: 300ms;
      -moz-transition-duration: 300ms;
      -ms-duration: 300ms;
      -webkit-transition-property: color, border-bottom-color;
      -ms-transition-property: color, border-bottom-color;
      -moz-transition-property: color, border-bottom-color;
      transition-duration: 300ms;
      transition-property: color, border-bottom-color;

      &:hover {
        width: 1.7em;
        color: $line-menu;
        border-bottom-color: $line-menu;
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .top__menu {

    .menu {

      ul {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .top__menu {
    border-bottom: none;

    .menu {
      position: fixed;
      z-index: 6;

      li {
        padding-right: 0.63em;
      }
    }
  }


    .menu__btn {
      display: flex; /* используем flex для центрирования содержимого */
      align-items: center; /* центрируем содержимое кнопки */
      position: fixed;
      top: 2em;
      left: 1.3em;
      width: 1.6em;
      height: 1.6em;
      cursor: pointer;
      z-index: 6;
    }

    /* добавляем "гамбургер" */
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
      width: 100%;
      height: 0.13em;
      background-color: $line-menu;
      z-index: 5;
      position: absolute;
    }

    .menu__btn > span::before {
      content: '';
      top: -0.8em;
    }

    .menu__btn > span::after {
      content: '';
      top: 0.8em;
    }

    .menu__box {
      display: flex;
      flex-direction: column;
      position: fixed;
      visibility: hidden;
      top: 0;
      left: -100%;
      width: 19em;
      height: 100%;
      margin: 0;
      padding: 2.8em 0;
      list-style: none;
      text-align: center;
      background-color: #EFEFEF;
      box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
      z-index: 5;


    }

    /* элементы меню */
    .menu__item {
      display: block;
      padding: 0.63em;
      color: #333;
      font-family: 'Quicksand', sans-serif;
      font-size: 1.3em;
      font-weight: 400;
      text-decoration: none;
    }

    .menu__item:hover {
      background-color: #CFD8DC;
    }

    #menu__toggle:checked ~ .menu__btn > span {
      transform: rotate(45deg);
      z-index: 1000;
    }

    #menu__toggle:checked ~ .menu__btn > span::before {
      top: 0;
      transform: rotate(0);
    }

    #menu__toggle:checked ~ .menu__btn > span::after {
      top: 0;
      transform: rotate(90deg);
    }

    #menu__toggle:checked ~ .menu__box {
      visibility: visible;
      left: 0;
    }

    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      transition-duration: .25s;
    }

    .menu__box {
      transition-duration: .15s;
    }

    .menu__item {
      transition-duration: .15s;
    }
  }


@media screen and (max-width: 700px) {

  .menu__box {
    width: 16em;
    padding: 2em 0;
  }

  .menu__item {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 600px) {
  .menu__box {
    width: 12em;
    padding: 0;
  }
}

@media screen and (max-width: 430px) {
  .menu__box {
    width: 10em;
    padding: 0;
  }

  .menu__item {
    font-size: 1em;
  }
}





