.container {
  #toTop {
    width: 60px;
    text-align: center;
    padding: 5px;
    position: fixed;
    bottom: 20px;
    right: 5px;
    cursor: pointer;
    height: 20px;
    vertical-align: middle;
  }
}

.top__container {
  border-bottom: 2px solid $top-line;
  padding: 0.63em 0;

  .top__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .top__left {
      max-width: 100%;

      .top__logo {

        img {
          display: block;
          max-width: 300px;
        }

        a {

          &:focus {
            outline: none;
          }
        }
      }
    }

    .top__right {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 15px 0;

      .top__clock {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 1.250em;

        .img__time {
          padding: 0.63em;

          img {
            display: block;
            max-width: 50px;
          }
        }

        .top__contact {
          margin: 6% 0;

          p:first-of-type {
            font-size: 0.88em;
            line-height: 1.5em;
            color: $dark-black;
            font-weight: 400;
          }
          p:last-of-type {
            font-size: 0.88em;
            line-height: 1em;
            color: $dark-black;
            font-weight: 400;
            padding-top: 5px;
          }

        }
      }

      .top__phone {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 1.250em;

        .img__phone {
          padding: 0.63em;

          img {
            max-width: 50px;
            display: block;
          }
        }

        .top__contact {
          margin: 6% 0;

          p {
            font-size: 0.88em;
            line-height: 1.5em;
            color: $dark-black;
            font-weight: 400;
            padding-bottom: 5px;
          }

          a {
              font-size: 0.88em;
              line-height: 1em;
              color: $dark-black;
              font-weight: 400;
              color: $dark-black;
              text-decoration: none;
              font-weight: 400;
            }


        }
      }

      .top__route {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 0;

        .img__route {
          padding: 0.63em;

          img {
            display: block;
            max-width: 52px;
          }
        }

        .top__contact {
          margin: 8% 0;

          p:first-of-type {
            font-size: 0.88em;
            line-height: 1.5em;
            color: $dark-black;
            font-weight: 400;
          }
          p:last-of-type {
            font-size: 0.88em;
            line-height: 1em;
            font-weight: 400;
            color: $dark-black;
            padding-top: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .top__container {
    padding: 0.31em 0 0.31em 0;

    .top__wrap {

      .top__right {
        display: none;
      }

      .top__left {
        margin: 0 auto;
      }
    }
  }
}


@media screen and (max-width: 525px) {

  .container {
    #toTop {
      right: -22px;

      img {
        max-width: 18px;
      }

    }
  }

  .top__container {

    .top__wrap {

      .top__left {

        .top__logo {

          img {
            max-width: 250px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .top__container {

    .top__wrap {

      .top__left {

        .top__logo {

          img {
            max-width: 200px;
          }
        }
      }
    }
  }
}




