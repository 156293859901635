@mixin min-max($min-width, $max-width, $padding) {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin btn($bg: $slider, $two-button: false) {
  width: 225px;
  border-radius: 25px;
  background-color: $bg;
  border: 2px solid $bg;
  box-shadow: 0 2px 5px rgba(0,0,0,.25);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding: 18px 0;
  letter-spacing: 0.14px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  color: $dark-gray;
  box-sizing: border-box;
  transition-duration: 500ms;
  transition-property: background-color, border-color;
  @content;

  &:hover {
    background-color: darken($line-menu, 10%);
    border-color: darken($line-menu, 10%);
  }

  @if $two-button == true {

    &:first-child {
      margin-right: 25px;
    }

    &:last-child {
      background-color: transparent;
      border-color: $white;
    }

    &:hover {

      &:last-child {
        background-color: $line-menu;
        border-color: $line-menu;
        color: $white;
      }

      &:first-child {
        color: $white;
      }

    }

  }

}


@mixin devider($color-line: $line-menu) {
  display: block;
  width: 7px;
  position: relative;
  margin: 20px auto;

  p {
    text-align: center;

    &::after {
      content: "";
      width: 7px;
      height: 7px;
      background-color: $color-line;
      display: block;
      border-radius: 50%;
    }
  }

  &::after, &::before {
    content: "";
    width: 80px;
    height: 1px;
    background-color: $slider;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-1px);
  }

  &::before {
    left: -90px;

  }

  &::after {
    right: -90px;
  }
}