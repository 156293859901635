.specialist {
  background-image: url("../img/specialist.jpg");
  background-size: cover;
  display: block;
  max-width: 100%;
  z-index: 1;
  padding-bottom: 50px;

  .wrap {

    .specialist__title {
      display: flex;
      justify-content: center;

      h1 {
        padding-top: 50px;
        font-family: 'Abhaya Libre', serif;
        font-size: 4.38em;
        line-height: 1em;
        font-weight: 800;
        color: $line-menu;
      }
    }

    .devider {
      @include devider()
    }

    .specialist__wrap {
      background-color: $white;
      box-shadow: 0 3px 5px rgba(0,0,0,.1);
      box-sizing: border-box;
      padding: 30px 50px 80px;
      flex-basis: 100%;

      .specialist__block {

        .block__info {
          padding: 10px 0;

          h2 {
            font-size: 1.250em;
            color: $slider;
            font-weight: 700;
            padding-bottom: 3px;
          }

          p {
            font-size: 1.250em;
            line-height: 1.938em;
            font-weight: 400;
            color: $black;
            text-align: justify;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px){

  .specialist {

    .wrap {

      .specialist__wrap{
        padding: 30px;
      }
    }
  }
}

@media screen and (max-width: 960px){

  .specialist {

    .wrap {

      .specialist__title {

        h1 {
          padding-top: 30px;
        }
      }

    }
  }
}

@media screen and (max-width: 860px) {

  .specialist {

    .wrap {

      .specialist__title {

        h1 {
          font-size: 3.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {

  .specialist {

    .wrap {

      .specialist__title {

        h1 {
          font-size: 2.5em;
        }
      }

      .specialist__wrap {

        .specialist__block {

          .block__info {
            padding: 5px 0;

            h2 {
              font-size: 1.2em;
            }

            p {
              font-size: 1.2em;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .specialist {
    padding-bottom: 30px;

    .wrap {

      .specialist__title {

        h1 {
          font-size: 1.7em;
          padding-top: 15px;
        }
      }

      .devider {

        p {

          &:after {
            width: 5px;
            height: 5px;
          }
        }

        &::after, &::before {
          width: 60px;
        }

        &::before {
          left: -70px;

        }

        &::after {
          right: -70px;
        }
      }

      .specialist__wrap {

        .specialist__block {

          .block__info {
            padding: 5px 0;

            h2 {
              font-size: 1em;
            }

            p {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .specialist {

    .wrap {

      .specialist__title {

        h1 {
          font-size: 1.4em;
          padding-top: 10px;
        }
      }

      .specialist__wrap {

        .specialist__block {

          .block__info {

            h2 {
              font-size: 0.875em;
            }

            p {
              font-size: 0.875em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .specialist {
    padding-bottom: 10px;

    .wrap {

      .specialist__wrap {

        .specialist__block {

          .block__info {

            h2 {
              font-size: 0.750em;
            }

            p {
              font-size: 0.750em;
            }
          }
        }
      }
    }
  }
}







