.info__license {
  background-color: $white;
  box-shadow: 0 3px 5px rgba(0,0,0,.1);
  box-sizing: border-box;
  padding: 30px 50px 30px;
  flex-basis: 100%;

  .title {
    font-size: 1.250em;
    color: $slider;
    font-weight: 700;
  }

   p {
     font-size: 1.250em;
     line-height: 1.938em;
     font-weight: 400;
     color: $black;
     text-align: justify;
   }

  .license__list {
    padding-left: 20px;

    ul {
      list-style-image: url("/img/tooth.png");

      li {
        font-size: 1.250em;
        line-height: 1.938em;
        font-weight: 400;
        color: $black;
        text-align: justify;
      }
    }
  }


  .last__p {
    color: $dark-gray;
  }
}

@media screen and (max-width: 1024px) {

  .license {

    .info__license {
      padding: 30px;
    }

    .bottom__license {

      .license__img {

        img {
          padding: 5px;

          &:hover {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            transform: scale(1.2);
          }

        }
      }
    }
  }
}

@media screen and (max-width: 960px) {

  .license {

    .license__title {
      padding-top: 30px;

    }
    .bottom__license {
      margin: 50px auto;

      .license__img {
        max-width: 100%;

        img {
          padding: 35px;

          &:hover {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            transform: scale(1.2);
          }

        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .license {

    .license__title {
      font-size: 3.5em;
    }
  }
}

@media screen and (max-width: 700px) {
  .license {

    .license__title {
      font-size: 2.5em;
    }

    .title {
      font-size: 1.2em;
    }

    p {
      font-size: 1.2em;
      line-height: 1.5em;
    }

    .info__license {

      .license__list {

        li {
          font-size: 1.2em;
          line-height: 1.5em;
        }
      }
    }

    .bottom__license {
      margin: 25px auto;

      .license__img {

        img {
          padding: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .license {

    .license__title {
      padding-top: 15px;
      font-size: 1.7em;
    }

    .title {
      font-size: 1em;
    }

    p {
      font-size: 1em;
    }

    .info__license {

      .license__list {

        li {
          font-size: 1em;
        }
      }
    }

    .bottom__license {
      margin: 25px auto;

      .license__img {

        img {
          max-width: 200px;
          padding: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .license {

    .license__title {
      padding-top: 10px;
      font-size: 1.4em;
    }

    .title {
      font-size: 0.875em;
    }

    p {
      font-size: 0.875em;
    }

    .info__license {

      .license__list {

        li {
          font-size: 0.875em;
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .license {

    .title {
      font-size: 0.750em;
    }

    p {
      font-size: 0.750em;
    }

    .info__license {

      .license__list {

        li {
          font-size: 0.750em;
        }
      }
    }
  }
}

