$white: #fff;
$dark-black: #000000;
$black: #222222;
$dark-gray: #444444;

$top-line: #f2f2f2;
$line-menu: #6b4c76;
$slider: #cf83ce;

$bg-price: #e6e6e8;
$tr-price: #bfaec1;
$white-gray: #f4f5f9;