.price {

  .price__wrap {
    background-image: url("../img/price.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    max-width: 100%;
    z-index: 1;

    .devider {
      @include devider()
    }

    p {
      font-size: 1em;
      line-height: 1.938em;
      font-weight: lighter;
      color: $dark-gray;
      text-align: justify;
      padding-bottom: 10px;
      padding-left: 5px;
    }

    .price__title {
      display: flex;
      justify-content: center;

      h1 {
        padding-top: 50px;
        font-family: 'Abhaya Libre', serif;
        font-size: 4.38em;
        line-height: 1em;
        font-weight: 800;
        color: $line-menu;
      }
    }

    .content__price {
      padding-bottom: 9.375em;

      .price__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .price__link {
          display: flex;
          margin: 1.250em;
          background: $white;
          width: 18.750em;
          height: 12.500em;
          box-shadow: 0 0 5px;
          border-radius: 0.625em;

          &:hover {
            box-shadow: 0.750em 0.750em 0.750em 0.063em rgba(0, 0, 0, 0.5);
          }


          .button__price {
            display: block;
            width: 100%;
            padding: 2.5em;
            justify-content: center;
            font-size: 1.125em;
            font-weight: 400;
            line-height: 1.938em;
            text-decoration: none;
            text-align: center;
            color: $line-menu;

            img {
              margin: 0 auto;
              display: block;
              max-width: 100%;
            }
          }

          .overlay {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            display: none;
            /* фон затемнения */
            background-color: rgba(0, 0, 0, 0.65);
            position: fixed; /* фиксированное поцизионирование */
            cursor: default; /* тип курсара */
          }

          .overlay:target {
            display: block;
          }

          .popup {
            top: -100%;
            right: 0;
            left: 50%;
            font-size: 0.875em;
            z-index: 20;
            margin: 0;
            width: 85%;
            min-width: 320px;
            max-width: 1200px;
            /* фиксированное позиционирование, окно стабильно при прокрутке */
            position: absolute;
            padding: 0.938em;
            border: 0.063em solid #383838;
            background: #fefefe;
            /* скругление углов */
            -webkit-border-radius: 0.250em;
            -moz-border-radius: 0.250em;
            -ms-border-radius: 0.250em;
            border-radius: 0.250em;
            /* внешняя тень */
            -webkit-box-shadow: 0 0.938em 1.250em rgba(0, 0, 0, .22), 0 1.188em 3.750em rgba(0, 0, 0, .3);
            -moz-box-shadow: 0 0.938em 1.250em rgba(0, 0, 0, .22), 0 1.188em 3.750em rgba(0, 0, 0, .3);
            -ms-box-shadow: 0 0.938em 1.250em rgba(0, 0, 0, .22), 0 1.188em 3.750em rgba(0, 0, 0, .3);
            box-shadow: 0 0.938em 1.250em rgba(0, 0, 0, .22), 0 1.188em 3.750em rgba(0, 0, 0, .3);
            -webkit-transform: translate(-50%, -500%);
            -ms-transform: translate(-50%, -500%);
            -o-transform: translate(-50%, -500%);
            transform: translate(-50%, -500%);
            -webkit-transition: -webkit-transform 0.6s ease-out;
            -moz-transition: -moz-transform 0.6s ease-out;
            -o-transition: -o-transform 0.6s ease-out;
            transition: transform 0.6s ease-out;

            &:focus {

            }

            .title {
              font-size: 1.250em;
              line-height: 1.250em;
              color: $dark-gray;
              font-weight: 400;
              text-align: center;
              margin-bottom: 0.313em;
            }

            table {
              display: flex;
              justify-content: center;
              min-width: 300px;
              max-width: 1200px;
              text-align: left;

              .first__tr {
                height: 1.250em;
              }

              tbody {
                width: 100%;
              }

              tr {
                width: 100%;

                &:nth-child(even) {
                  background-color: $tr-price;
                }

                th {
                  background-color: $line-menu;
                  color: $white;
                  text-align: left;
                  font-size: 1.250em;
                  padding: 0.313em 11.250em 0.313em 0;
                }

                td {
                  padding-bottom: 0.500em;
                  flex-wrap: nowrap;
                  width: 100%;
                  font-size: 1.250em;
                  vertical-align: middle;
                }
              }
            }

            .title__table {
              font-size: 1.000em;
              line-height: 1.000em;
              color: $dark-gray;
              font-weight: 400;
              text-align: center;
            }

            .last__table {

              .first__tr {
                height: 0.313em;
              }

              tr {

                th {
                  font-size: 1.000em;
                }

                td {
                  font-size: 1.063em;
                }
              }
            }
          }

            .overlay:target + .popup {
              -webkit-transform: translate(-50%, 0);
              -ms-transform: translate(-50%, 0);
              -o-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
              top: 32%;
            }

            .close {
              top: -0.625em;
              right: -0.625em;
              width: 1.250em;
              height: 1.250em;
              position: absolute;
              padding: 0;
              border: 0.125em solid #ccc;
              -webkit-border-radius: 0.938em;
              -moz-border-radius: 0.938em;
              -ms-border-radius: 0.938em;
              -o-border-radius: 0.938em;
              border-radius: 0.938em;
              background-color: rgba(61, 61, 61, 0.8);
              -webkit-box-shadow: 0 0 0.625em $dark-black;
              -moz-box-shadow: 0 0 0.625em $dark-black;
              box-shadow: 0 0 0.625em $dark-black;
              text-align: center;
              text-decoration: none;
              font: 0.813em/1.250em 'Tahoma', Arial, sans-serif;
              font-weight: bold;
              -webkit-transition: all ease .8s;
              -moz-transition: all ease .8s;
              -ms-transition: all ease .8s;
              -o-transition: all ease .8s;
              transition: all ease .8s;
            }

            .close:before {
              color: rgba(255, 255, 255, 0.9);
              content: "X";
              text-shadow: 0 -0.063em rgba(0, 0, 0, 0.9);
              font-size: 0.750em;
            }

            .close:hover {
              background-color: $line-menu;
              -webkit-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
@media screen and (max-width: 1308px) {

  .price {

    .price__wrap {
      .content__price {
        .price__content {
          .price__link {
            .popup {
              table {
                tr {
                  th {
                    padding: 0.313em 6.000em 0.313em 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  .price {

    .price__wrap {


      .content__price {
        padding-bottom: 6.250em;

        .price__content {

          .price__link {
            width: 14.375em;
            height: 13.750em;
            margin: 0.625em;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 1002px) {

  .price {

    .price__wrap {

      .content__price {

        .price__content {

          .price__link {
            width: 12.500em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .price {

    .price__wrap {

      .price__title {
         h1 {
           padding-top: 30px;
         }
      }
    }
  }

}

@media screen and (max-width: 884px) {

.price {

  .price__wrap {

    .content__price {

      .price__content {

        .price__link {
          width: 18.750em;
          height: 13.750em;

          .popup {
            table {
              tr {
                th {
                  padding: 0.313em 3.000em 0.313em 0;;
                }

                .border {
                  border-left: 1px solid $black;
                }
              }
            }
          }
        }
      }
    }
  }
}
}

@media screen and (max-width: 860px) {

  .price {

    .price__wrap {

      .price__title {

        h1 {
          font-size: 3.5em;
        }
      }
    }
  }

}

@media screen and (max-width: 700px) {

  .price {
    padding: 0;

    .price__wrap {

      p {
        text-align: center;
        font-size: 1em;
      }

      .price__title {

        h1 {
          font-size: 2.5em;
        }
      }

      .content__price {
        padding-bottom: 0;

        .price__content {

          .price__link {
            width: 14.063em;
            height: 12.500em;

            .overlay:target + .popup {
              top: 15%;
            }

            .popup {

              .title {
                font-size: 1em;
                line-height: 1em;
              }

              table {

                 tr {

                   th {
                     font-size: 1.2em;
                     padding: 0.313em 1.000em 0.313em 0;
                   }

                   td {
                     font-size: 1.2em;

                   }
                 }

              }

            }

            .button__price {
              padding: 1.250em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 548px) {

  .price {

    .price__wrap {

      p {
        font-size: 0.875em;
      }

      .devider {

        p {

          &:after {
            width: 5px;
            height: 5px;
          }
        }

        &::after, &::before {
          width: 60px;
        }

        &::before {
          left: -70px;

        }

        &::after {
          right: -70px;
        }
      }

      .price__title {

        h1 {
          font-size: 1.7em;
          padding-top: 15px;
        }
      }

      .content__price {

        .price__content {

          .price__link {
            width: 12.500em;
            height: 12.500em;

            .overlay:target + .popup {
              top: 15%;
            }

            .popup {

              .title {
                font-size: 0.875em;
              }

              table {

                tr {

                  th {
                    font-size: 1em;
                  }

                  td {
                    font-size: 1em;

                  }
                }

              }

            }

            .button__price {
              font-size: 1.2em;
              padding: 0.938em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {

  .price {

    .price__wrap {

      p {
        padding-bottom: 5px;
      }

      .content__price {

        .price__content {

          .price__link {
            width: 11.250em;
            height: 9.375em;

            .overlay:target + .popup {
              top: 10%;
            }

            .popup {

              .title {
                font-size: 0.875em;
              }

              table {

                tr {

                  .first__tr {
                    display: none;
                  }

                  th {
                    font-size: 0.875em;
                  }

                  td {
                    font-size: 0.875em;

                  }
                }

              }

            }

            .button__price {
              font-size: 1em;
              padding: 0.625em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {

  .price {

    .price__wrap {

      p {
        font-size: 0.750em;
        padding-bottom: 0;
        line-height: 16px;
      }

      .price__title {

        h1 {
          padding-top: 10px;
          font-size: 1.4em;
        }
      }

      .content__price {

        .price__content {

          .price__link {
            width: 9.375em;
            height: 10.625em;

            .overlay:target + .popup {
              top: 10%;
            }

            .popup {
              padding: 0.313em;

              .title {
                font-size: 0.875em;
              }

              table {

                tr {

                  th {
                    font-size: 0.875em;
                  }

                  td {
                    font-size: 0.875em;

                  }
                }

              }

            }

            .button__price {
              font-size: 0.875em;
              padding: 0.625em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {

  .price {

    .price__wrap {

      .content__price {

        .price__content {

          .price__link {
            width: 7.500em;
            height: 9.375em;

            .close {
              display: none;
            }

            .popup {
              padding: 3px;
              left: 50%;
              width: 280px;

              table {
                min-width: 280px;

                .first__tr {
                  display: none;
                }

                tr {

                  th {
                    font-size: 0.875em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



